import { ErrorCodeUsingGETValueEnum } from '../../api/accounting';

export const economicPlanTranslations = {
  property: {
    en: 'Property',
    de: 'WEG',
  },
  wka: {
    en: 'WKA',
    de: 'Wärmekostenabrechnung',
  },
  actions: {
    copy: {
      en: 'Create a copy',
      de: 'Kopie erstellen',
    },
    delete: {
      en: 'Delete',
      de: 'Löschen',
    },
  },
  createNewWka: {
    en: 'Create new WKA',
    de: 'Neue WKA erstellen',
  },
  notifications: {
    economicPlanContext: {
      loadEconomicPlanCreationDataError: {
        message: {
          en: 'Failed to load account distribution keys',
          de: 'Fehler beim Laden der Verteilerschlüssel',
        },
      },
      loadEconomicPlanListError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load economic plans.',
          de: 'Wirtschaftspläne konnten nicht geladen werden.',
        },
      },
      loadEconomicPlanError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load economic plan.',
          de: 'Wirtschaftsplan kann nicht geladen werden.',
        },
      },
      activateError: {
        message: {
          en: 'Economic plan could not be activated',
          de: 'Wirtschaftsplan konnte nicht aktiviert werden',
        },
      },
      activateSuccess: {
        message: {
          en: 'Economic plan activated successfully',
          de: 'Wirtschaftsplan erfolgreich aktiviert',
        },
      },
      loadingWkasError: {
        en: 'Failed to load WKAs.',
        de: 'WKAs konnten nicht geladen werden.',
      },
    },
    downloadError: {
      message: {
        en: 'Download failed',
        de: 'Download fehlgeschlagen',
      },
    },
    copyEconomicPlanSuccess: {
      message: {
        en: 'The new economic plan has been created.',
        de: 'Der neue Wirtschaftsplan wurde erstellt.',
      },
    },
    copyEconomicPlanError: {
      message: {
        en: 'Copy failed.',
        de: 'Fehler beim Kopieren der Wirtschaftsplan.',
      },
    },
    deleteEconomicPlanSuccess: {
      message: {
        en: 'The economic plan has been deleted.',
        de: 'Der Wirtschaftsplan wurde gelöscht.',
      },
    },
    deleteEconomicPlanError: {
      message: {
        en: 'Deletion failed.',
        de: 'Fehler beim Löschender Wirtschaftsplan.',
      },
    },
    loadEconomicPlanError: {
      message: {
        en: 'Resource loading error.',
        de: 'Fehler beim Laden der Ressource.',
      },
      description: {
        en: 'Cannot load economic plan.',
        de: 'Wirtschaftsplan kann nicht geladen werden.',
      },
    },
    loadUnitEconomicPlanError: {
      message: {
        en: 'Cannot load economic plan for unit.',
        de: 'Wirtschaftsplan fur Einheit kann nicht geladen werden.',
      },
    },
    saveSuccess: {
      message: {
        en: 'Economic plan saved successfully.',
        de: 'Wirtschaftsplan erfolgreich gespeichert.',
      },
    },
    saveError: {
      message: {
        en: 'Economic plan could not be saved',
        de: 'Wirtschaftsplan konnte nicht gespeichert werden',
      },
    },
  },
  economicPlanList: {
    create: {
      en: 'Create New',
      de: 'Neu',
    },
    ACCOUNT_DISTRIBUTION: {
      en: 'Account Based',
      de: 'Kontenbasiert',
    },
    UNIT_FIGURES: {
      en: 'Unit Figures',
      de: 'Beträge pro Einheit',
    },
    loadEconomicPlanListError: {
      message: {
        en: 'Loading error',
        de: 'Ladefehler',
      },
      description: {
        en: 'Could not load economic plans.',
        de: 'Wirtschaftsplan konnten nicht geladen werden.',
      },
    },
    column: {
      status: {
        en: 'Status',
        de: 'Status',
      },
      startDate: {
        en: 'From',
        de: 'Gültig ab',
      },
      title: {
        en: 'Title',
        de: 'Titel',
      },
      updatedBy: {
        en: 'Last editor',
        de: 'Bearbeiter',
      },
      updated: {
        en: 'Last edited',
        de: 'Zuletzt bearbeitet',
      },
      type: {
        en: 'Type',
        de: 'Kategorie',
      },
    },
    status: {
      DRAFT: {
        en: 'Draft',
        de: 'Entwurf',
      },
      DECIDED: {
        en: 'Upcoming',
        de: 'Bevorstehend',
      },
      PREPARED: {
        en: 'Prepared',
        de: 'Vorbereitet',
      },
      ACTIVE: {
        en: 'Active',
        de: 'Aktiv',
      },
      EXPIRED: {
        en: 'Inactive',
        de: 'Inaktiv',
      },
      DELETED: {
        en: 'Deleted',
        de: 'Gelöscht',
      },
    },
  },
  propertyInvalidError: {
    en: 'Property is in an invalid state. Make sure you have an active contract for all units.',
    de: 'Objekt ist in einem ungültigen Status. Stellen Sie sicher dass für alle Einheiten ein aktiver Vertrag erstellt ist.',
  },
  economicPlan: {
    startDate: {
      label: {
        en: 'Effective From',
        de: 'Wirksam ab',
      },
    },
    title: {
      en: 'Title',
      de: 'Titel',
    },
    options: {
      systemGenerated: {
        en: 'System generated',
        de: 'System generiert',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
    },
    notification: {
      errorCodes: {
        [ErrorCodeUsingGETValueEnum.BAD_CONTACT_ADDRESS]: {
          en: errorBody => `${errorBody.errorEntityName} does not have an address`,
          de: errorBody => `${errorBody.errorEntityName} hat keine Adresse`,
        },
        [ErrorCodeUsingGETValueEnum.DUPLICATE_START_DATE]: {
          en: () => 'There is an economic plan already decided with the same start date.',
          de: () => 'Es gibt bereits einen Wirtschaftsplan mit demselben Startdatum. Bitte wählen Sie ein anderes Datum oder setzen Sie den bestehenden Wirtschaftsplan in den Status Entwurf.',
        },
        [ErrorCodeUsingGETValueEnum.INVALID_ACCOUNT_AMOUNTS]: {
          en: () => 'Action not allowed due to accounts with mismatching value distributions.',
          de: () => 'Aktion nicht erlaubt aufgrund von nicht übereinstimmende Verteilerschlüsselwerte.',
        },
        [ErrorCodeUsingGETValueEnum.MISSING_CORRECTION_BOOKING_DATE]: {
          en: () => 'Missing corrections booking date. Cannot activate economic plan.',
          de: () => 'Fehlendes Korrekturbuchungsdatum. Wirtschaftsplan kann nicht aktiviert werden.',
        },
        [ErrorCodeUsingGETValueEnum.INVALID_PROPERTY]: {
          en: () => 'Please ensure that all units have an active contract as of the start date of the economic plan.',
          de: () => 'Bitte stellen Sie sicher, dass alle Einheiten über einen aktiven Vertrag ab dem Startdatum des Wirtschaftsplans verfügen.',
        },
      },
      recreateEconomicPlanError: {
        message: {
          en: 'Cannot recreate economic plan.',
          de: 'Kann den Wirtschaftsplan nicht neu erstellen.',
        },
      },
      statusChangeError: {
        en: 'Failed to change status to: ',
        de: 'Status konnte nicht verändert werden zum: ',
      },
      activationErrorTitle: {
        en: "EP couldn't be activated",
        de: 'Wirtschaftsplan konnte nicht aktiviert werden',
      },
    },
    header: {
      title: {
        en: 'Economic Plan',
        de: 'Wirtschaftsplan',
      },
      administrator: {
        en: 'Administrator:',
        de: 'Objektbetreuer:',
      },
      generatedOn: {
        en: 'Generated on',
        de: 'Erstellt am',
      },
      primaryButton: {
        prepare: {
          en: 'Mark Prepared',
          de: 'Als Vorbereitet markieren',
        },
        finalize: {
          en: 'Activate',
          de: 'Aktivieren',
        },
      },
      buttons: {
        save: {
          en: 'Save',
          de: 'Speichern',
        },
        edit: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
        download: {
          en: 'Download',
          de: 'Herunterladen',
        },
        recreate: {
          en: 'Recreate',
          de: 'Neu erstellen',
        },
        revertToDraft: {
          en: 'Revert to Draft',
          de: 'Auf Entwurf zurücksetzen',
        },
        send: {
          en: 'Preview and send',
          de: 'Vorschau & Versenden',
        },
        checkMessages: {
          en: 'View messages',
          de: 'Nachrichten ansehen',
        },
        close: {
          en: 'Close',
          de: 'Schließen',
        },
        generateDocuments: {
          en: 'Generate documents',
          de: 'Dokumente erstellen',
        },
      },
      messagesGeneratingTooltip: {
        en: 'Messages are currently generated for this process.',
        de: 'Es werden aktuell Nachrichten für diesen Prozess generiert.',
      },
      generationErrorMessage: {
        en: 'Document generation failed. Please try to recreate or',
        de: 'Dokumenten konnten nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder',
      },
      contactSupport: {
        en: 'contact support.',
        de: 'kontaktieren Sie unser Support Team.',
      },
    },
    validations: {
      title: {
        en: 'The following accounts have mismatching value distributions:',
        de: 'Die folgenden Konten haben nicht übereinstimmende Verteilerschlüsselwerte:',
      },
      help: {
        en: 'View the support page for more information',
        de: 'Hilfeseite anschauen',
      },
      assigned: {
        en: 'assigned value is',
        de: 'zugewiesener Wert ist',
      },
      distribution: {
        en: 'distribution value is',
        de: 'Verteilungswert ist',
      },
      source: {
        en: 'Open source',
        de: 'Anpassen',
      },
    },
    revertModal: {
      title: {
        en: 'Revert Economic Plan to Draft',
        de: 'Wirtschaftsplan als Entwurf zurücksetzen',
      },
      text: {
        en: 'Reverting the economic plan to DRAFT will also revert all corresponding postings and Bank Orders. Are you sure that you want to proceed?',
        de: 'Wenn Sie den Wirtschaftsplan auf Entwurf zurücksetzen, werden auch alle entsprechenden Buchungen und Bankaufträge zurückgesetzt. Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      confirmText: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
    },
    activationModal: {
      title: {
        en: 'Activate Economic Plan',
        de: 'Wirtschaftsplan aktivieren',
      },
      economicPlanFutureActivationText: {
        en: (startDate: string) => [`This Economic Plan will be activated on ${startDate}`],
        de: (startDate: string) => [`Dieser Wirtschaftsplan wird am ${startDate} aktiviert`],
      },
      economicPlanPastActivationText: {
        en: (startDate: string) => ['This Economic Plan will be activated today.',
          `Activation will generate postings starting from ${startDate} until today`],
        de: (startDate: string) => ['Dieser Wirtschaftsplan wird heute aktiviert.',
          `Durch das Aktivieren werden Buchungen für das Intervall vom ${startDate} bis heute generiert`],
      },
      economicPlanCorrectionsActivationText: {
        en: (startDate: string) => ['This Economic Plan will be activated today.',
          `Activation will generate correctional postings and direct debits for the interval starting from ${startDate} until today`],
        de: (startDate: string) => ['Dieser Wirtschaftsplan wird heute aktiviert.',
          `Durch das Aktivieren werden Korrekturbuchungen und Bankaufträge für das Intervall vom ${startDate} bis heute generiert`],
      },
      postingGenerationText: {
        en: (firstGenerationDate: string) => `Monthly Postings and Bank Orders will be generated starting from ${firstGenerationDate}`,
        de: (firstGenerationDate: string) => `Ab dem ${firstGenerationDate} werden monatliche Buchungen und Bankaufträge generiert.`,
      },
      missingHouseMoneyBankAccountListItem: {
        en: 'Currently this property does not have a bank account of type house money. Therefore no bank orders will generated, only upon adding a bank account of type house money to the property.',
        de: 'Dieses Objekt hat aktuell kein Bankkonto der Kategorie Hausgeld. Es werden keine Bankaufträge erstellt, bis Sie ein Bankkonto der Kategorie Hausgeld hinzugefügt haben.',
      },
      missingHouseMoneyBankAccountConfirmation: {
        en: 'Are you sure you want to activate this Economic Plan without a house money bank account?',
        de: 'Sind Sie sicher, dass Sie diesen Wirtschaftsplan ohne ein Hausgeld Bankkonto aktivieren möchten?',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      confirmText: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
    },
    startDateValidationNotification: {
      content: {
        en: 'Ensure that the start date is in the future.',
        de: 'Bitte stellen Sie sicher, dass das Startdatum in der Zukunft liegt.',
      },
    },
  },
};
