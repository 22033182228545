import { PropsWithChildren, createContext, useState } from 'react';
import { StoreApi, createStore } from 'zustand';
import { HeatingCenterDataExchangeStore } from './interfacesHeatingCenterDataExchange';
import {
  onInitializeDataExchangeTableFromHeatingCenterUnits,
  onChangeDirty, onChangeShowOnlySelectedUnits, onSelectAll, onSelectOne,
} from './actionsHeatingCenterDataExchange';


const createHeatingCenterDataExchangeStore = () => (
  createStore<HeatingCenterDataExchangeStore>(set => ({
    isDirty: false,
    selectedUnitIds: [],
    showOnlySelectedUnits: false,
    actions: {
      onChangeDirty: (...args) => onChangeDirty(set, ...args),
      onChangeShowOnlySelectedUnits: (...args) => onChangeShowOnlySelectedUnits(set, ...args),
      onSelectOne: (...args) => onSelectOne(set, ...args),
      onSelectAll: (...args) => onSelectAll(set, ...args),
      onInitializeDataExchangeTableFromHeatingCenterUnits: (...args) => onInitializeDataExchangeTableFromHeatingCenterUnits(set, ...args),
    },
  }))
);


export const HeatingCenterDataExchangeStoreContext = createContext<StoreApi<HeatingCenterDataExchangeStore> | null>(null);


export const HeatingCenterDataExchangeStoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [store] = useState(createHeatingCenterDataExchangeStore);

  return (
    <HeatingCenterDataExchangeStoreContext.Provider value={store}>
      {children}
    </HeatingCenterDataExchangeStoreContext.Provider>
  );
};
