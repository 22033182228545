import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { HeatingCenterDtoVatRelevanceEnum, HeatingCenterDto } from 'api/public';
import { isNil } from 'lodash';
import {
  HeatingCenterGeneralDataStore, HeatingCenterGeneralDataValidationStates, HeatingCenterGeneralInformation, HeatingCenterReportingSettings, TempCO2DistributionTypeEnum, TempHeatingCenterAreaEnum,
} from './interfacesHeatingCenterGeneralData';

/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Set = StoreApi<HeatingCenterGeneralDataStore>['setState'];

export const onChangeGeneralInformation = <K extends keyof HeatingCenterGeneralInformation>(set: Set, key: K, value: HeatingCenterGeneralInformation[K]) => (
  set(state => (
    produce(state, (draft) => {
      /* Set dirty flag */
      draft.isDirty = true;

      /* Set the value for the key */
      draft.generalInformation[key] = value;

      /* Clear validation error if field is edited */
      if (key in draft.validationStates) {
        draft.validationStates[key as keyof HeatingCenterGeneralDataValidationStates] = null;
      }
    })
  ))
);

export const onChangeReportingSettings = <K extends keyof HeatingCenterReportingSettings>(set: Set, key: K, value: HeatingCenterReportingSettings[K]) => (
  set(state => (
    produce(state, (draft) => {
      /* Set dirty flag */
      draft.isDirty = true;
      /* Set the value for the key */
      draft.reportingSettings[key] = value;

      /* If they choose "NO_OVERRIDE" then we clear the number value */
      if (key === 'areaType' && value === 'NO_OVERRIDE') {
        draft.reportingSettings.totalArea = null;
      }

      if (key === 'co2DistributionType' && value === 'NO_OVERRIDE') {
        draft.reportingSettings.co2DistributionPercentage = null;
      }

      /**
       * SelectInputWithLink uses SelectInput with mode multiple, which means that an arrays is passed to onChange as value
       */
      if (
        (key === 'heatingDefaultDistributionId' || key === 'warmWaterDefaultDistributionId')
        && Array.isArray(value)
      ) {
        // eslint-disable-next-line prefer-destructuring
        draft.reportingSettings[key] = value[0];
      }

      /* Clear validation error if field is edited */
      if (key in draft.validationStates) {
        draft.validationStates[key as keyof HeatingCenterGeneralDataValidationStates] = null;
      }
    })
  ))
);


export const onChangeDirty = (set: Set, isDirty: boolean) => (
  set(state => (
    produce(state, (draft) => {
      draft.isDirty = isDirty;
    })
  ))
);


export const onChangeValidationStates = (set: Set, validationStates: HeatingCenterGeneralDataValidationStates) => (
  set(state => (
    produce(state, (draft) => {
      draft.validationStates = validationStates;
    })
  ))
);

export const onInitializeFormDataFromHeatingCenterDto = (set: Set, heatingCenterProjection: HeatingCenterDto) => (
  set(state => (
    produce(state, (draft) => {
      draft.generalInformation = {
        name: heatingCenterProjection.name,
        country: heatingCenterProjection.country,
        houseNumber: heatingCenterProjection.houseNumber,
        location: heatingCenterProjection.location,
        postalCode: heatingCenterProjection.postalCode,
        street: heatingCenterProjection.street,
      };

      draft.reportingSettings = {
        areaType: isNil(heatingCenterProjection.totalArea) ? TempHeatingCenterAreaEnum.NO_OVERRIDE : TempHeatingCenterAreaEnum.OVERRIDE,
        co2DistributionType: isNil(heatingCenterProjection.co2DistributionPercentage) ? TempCO2DistributionTypeEnum.NO_OVERRIDE : TempCO2DistributionTypeEnum.OVERRIDE,
        co2DistributionPercentage: heatingCenterProjection.co2DistributionPercentage,
        heatConnectionAfter2023: heatingCenterProjection.heatConnectionAfter2023,
        heatingDefaultDistributionId: heatingCenterProjection.heatingDefaultDistributionId,
        markNr1: heatingCenterProjection.markNr1,
        markNr2: heatingCenterProjection.markNr2,
        nonResidentialBuilding: heatingCenterProjection.nonResidentialBuilding,
        totalArea: heatingCenterProjection.totalArea,
        vatRelevance: heatingCenterProjection.vatRelevance as unknown as HeatingCenterDtoVatRelevanceEnum,
        warmWaterDefaultDistributionId: heatingCenterProjection.warmWaterDefaultDistributionId,
      };
    })
  ))
);
