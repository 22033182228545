import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { ConnectionLegacyDto, ConnectionsApi } from 'api/app';

const JAVASCRIPT_APP_ID = 17;
const injectedScripts = {};


export const useLoadJsPlugins = () => {
  const { appApiConfiguration } = useContext(AuthContext);
  const connectionController = new ConnectionsApi(appApiConfiguration('app'));

  async function loadJsPlugins() {
    try {
      const connections = await connectionController.getConnectionsUsingGET({ appId: JAVASCRIPT_APP_ID });
      connections.content
        // it is not expected to run this twice, but just in case
        .filter(conn => !injectedScripts[conn.id])
        .forEach((conn) => {
          injectedScripts[conn.id] = conn;
          injectScriptIntoBody(conn);
        });
    } catch (e) {
      console.warn('Loading js applications to inject has failed', e);
    }
  }

  function injectScriptIntoBody(conn: ConnectionLegacyDto) {
    try {
      const tag = document.createElement('script');
      const { url } = JSON.parse(conn.externalConfig);
      if (url) {
        tag.type = 'text/javascript';
        // do not block page parsing
        tag.async = true;
        tag.src = url;
        // add it to the end of the body so it does not block execution of any other content.
        document.body.appendChild(tag);
      } else {
        console.warn('No url defined in the externalconfig of the js plugin');
      }
    } catch (e) {
      console.warn('Could not load js application', conn, e);
    }
  }

  return { loadJsPlugins };
};
