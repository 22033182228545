import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { HeatingCenterCommonDataStore } from './interfacesHeatingCenterCommonData';


/* not declared with the rest of interfaces because these are just helper types to avoid long lines */
type Set = StoreApi<HeatingCenterCommonDataStore>['setState'];
type LoadedData = HeatingCenterCommonDataStore['loadedData'];

export const onChangeLoadedData = <K extends keyof LoadedData>(
  set: Set,
  key: K,
  value: LoadedData[K] | ((v: LoadedData[K]) => LoadedData[K]),
) => (
    set(state => (
      produce(state, (draft) => {
        if (typeof value === 'function') {
          draft.loadedData[key] = value(draft.loadedData[key]);
        } else {
          draft.loadedData[key] = value;
        }
      })
    ))
  );


export const onChangeLoading = (set: Set, loading: boolean) => (
  set(state => (
    produce(state, (draft) => {
      draft.loading = loading;
    })
  ))
);
