import { CasaviImportIdDtoAdministrationTypeEnum } from 'api/accounting';
import { Modal } from 'antd';

export interface PortalPropertyInterface {
    key: string,
    number: string,
    name: string,
    city: string,
    status: PropertyImportStatus,
    type: CasaviImportIdDtoAdministrationTypeEnum,
    errorDetails: string,
  }

export enum PropertyImportStatus {
  IDLE = 'IDLE',
  NO_IMPORT = 'NO_IMPORT',
  PENDING = 'PENDING',
  IMPORTING = 'IMPORTING',
  IMPORTED = 'IMPORTED',
  FAILED = 'FAILED',
  EXISTING = 'EXISTING',
}

export enum FaciliooRecordType {
  PROPERTY = 'PROPERTY',
  CONTACT = 'CONTACT',
}

export interface FaciliooResetExternalIdsIssue {
  title: string;
  status: number;
  id: number;
  error: string;
}

export interface FaciliooResetExternalIdsResponse {
  successfulCount: number;
  failedCount: number;
  issues: FaciliooResetExternalIdsIssue[];
}

export type ModalInstance = ReturnType<typeof Modal.confirm>;
