import { Skeleton, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { Link } from 'react-router-dom';
import { getContractEditingRoute } from 'pages/Property/routes';
import CellWithTwoLinesAndTag from 'storybook-components/table/CellWithTwoLinesAndTag/CellWithTwoLinesAndTag';
import { useContext, useMemo } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translationsHeatingCenterEditor } from 'pages/HeatingCenter/HeatingCenterEditorPage/translationsHeatingCenterEditor';
import { useBuildingsList } from 'services/BuildingsList/useBuildingsList';
import { useHeatingCenterLoadedData } from 'pages/HeatingCenter/HeatingCenterEditorPage/stores/CommonData/selectorsHeatingCenterCommonData';
import { PropertyDisplayDtoAdministrationTypeEnum, UnitContractProjectionDto, UnitProjectionDtoUnitTypeEnum } from 'api/accounting';
import { ICONS } from 'components/icons';
import { Icon } from '@material-ui/core';
import { Label } from 'storybook-components/Label';
import { Color } from 'theming';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';


export interface DataExchangeTableDataSourceItem {
  rowKey?: string | number,
  unitId: number,
  unitNrSharingDeclaration?: string,
  unitType?: UnitProjectionDtoUnitTypeEnum,
  isOwnedByWeg?: boolean,
  ownerContract?: UnitContractProjectionDto,
  tenantContract?: UnitContractProjectionDto,
  buildingName?: string,
  mscUnitId?: string,
  isChecked?: boolean,
  searchValue: string,
  mappingState?: 'success' | 'error',

  children?: Omit<DataExchangeTableDataSourceItem, 'children'>[];
}


const getMappingStateColor = (mappingState: DataExchangeTableDataSourceItem['mappingState']) => {
  if (mappingState === 'error') {
    return DotColorEnum.RED;
  }

  if (mappingState === 'success') {
    return DotColorEnum.GREEN;
  }

  return DotColorEnum.GRAY;
};


export const getMappingStateLabel = (mscUnitId: string, tl) => {
  if (mscUnitId) return tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mappingStates.mapped);

  return tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mappingStates.notMapped);
};


export const useHeatingCenterDataExchangeColumns = () => {
  const { tl } = useContext(LanguageContext);
  const { property, heatingCenterUnits, heatingCenter } = useHeatingCenterLoadedData();
  const { buildingsList } = useBuildingsList(property?.id);

  const {
    mscCustomerNumber, mscPropertyId, mscProviderKey, mscVersion,
  } = heatingCenter ?? {};
  const wasASetUploaded = !!(mscCustomerNumber || mscPropertyId || mscProviderKey || mscVersion);

  const isOwnerColumnDisplayed = property?.administrationType === PropertyDisplayDtoAdministrationTypeEnum.WEG;

  const columns: ColumnType<DataExchangeTableDataSourceItem>[] = useMemo(() => ([
    {
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.unitNr),
      dataIndex: 'unitNrSharingDeclaration',
      className: 'column-padding-medium',
      render: (unitNrSharingDeclaration, { unitType, children }) => (
        children
          ? null
          : (
            <CellWithTwoLines
              firstElement={(
                <Tooltip title={unitNrSharingDeclaration} trigger="hover" placement="topLeft">
                  {unitNrSharingDeclaration}
                </Tooltip>
              )}
              secondElement={tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.unitTypes[unitType])}
            />
          )
      ),
    },
    ...(isOwnerColumnDisplayed ? [{
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.owner),
      dataIndex: 'ownerContract',
      className: 'column-padding-medium',
      width: '25%',
      render: ownerContract => (
        <CellWithTwoLinesAndTag
          firstElement={(
            <Link
              to={getContractEditingRoute({
                propertyHrId: property?.propertyHrId,
                contractDbId: ownerContract?.unitContractId,
                unitDbId: ownerContract?.unitId,
              })}
              target="_blank"
            >
              <Tooltip title={ownerContract?.mailingContact?.name} trigger="hover" placement="topLeft">
                {ownerContract?.mailingContact?.name}
              </Tooltip>
            </Link>
          )}
          secondElement={null} // TODO PMP-24937
        />
      ),
    }] : []),
    {
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.tenant),
      dataIndex: 'tenantContract',
      className: 'column-padding-medium',
      width: '25%',
      render: (tenantContract: UnitContractProjectionDto) => (
        <CellWithTwoLinesAndTag
          firstElement={(
            <Link
              to={getContractEditingRoute({
                propertyHrId: property?.propertyHrId,
                contractDbId: tenantContract?.unitContractId,
                unitDbId: tenantContract?.unitId,
              })}
              target="_blank"
            >
              <Tooltip title={tenantContract?.mailingContact?.name} trigger="hover" placement="topLeft">
                {
                  tenantContract?.isVacant
                    ? tl(translationsHeatingCenterEditor.dataExchangeSection.table.vacancy)
                    : tenantContract?.mailingContact?.name
                }
              </Tooltip>
            </Link>
          )}
          secondElement={null} // TODO PMP-24937
        />
      ),
    },
    {
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.building),
      dataIndex: 'buildingName',
      render: buildingName => (
        <Skeleton
          active
          loading={buildingsList.loading}
          title={false}
          paragraph={{ rows: 1, width: '100%' }}
        >
          <PopoverTruncatingText
            value={buildingName}
            containerClassName="HeatingCenterDataExchangeBuildingName"
          />
        </Skeleton>
      ),
    },
    {
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mscId.label),
      dataIndex: 'mscUnitId',
      className: 'column-vertically-center-content',
      render: (mscUnitId, { mappingState, children }) => (
        children
          ? null
          : (
            <Skeleton
              active
              loading={heatingCenterUnits.loading}
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            >
              {
                mappingState === 'error'
                  ? (
                    <>
                      <Icon component={ICONS.warning} />
                      <Label label={tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mscId.error)} customTheme={{ color: Color.Yellow }} />
                    </>
                  )
                  : mscUnitId
              }
            </Skeleton>
          )
      ),
    },
    {
      title: tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mappingState),
      dataIndex: 'mappingState',
      render: (_, record) => (
        record.children
          ? null
          : (
            <Skeleton
              active
              loading={heatingCenterUnits.loading}
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            >
              <ColorDot color={getMappingStateColor(record.mappingState)}>{getMappingStateLabel(record.mscUnitId, tl)}</ColorDot>
            </Skeleton>
          )
      ),
    },
  ]),
  [tl, buildingsList.loading, heatingCenterUnits.loading, wasASetUploaded, isOwnerColumnDisplayed]);


  return { columns, buildingsList, wasASetUploaded };
};
