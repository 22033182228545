export const translations = {
  createProfitAndLossButtonLabel: {
    en: 'Create new',
    de: 'Neuen Eigentümerbericht erstellen',
  },
  table: {
    name: {
      en: 'Title',
      de: 'Titel',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    propertyIdInternal: {
      en: 'Property Internal ID',
      de: 'Objekt-Nr Intern',
    },
    propertyName: {
      en: 'Property Name',
      de: 'Objektbezeichnung',
    },
    accountant: {
      en: 'Accountant',
      de: 'Buchhalter',
    },
    administrator: {
      en: 'Administrator',
      de: 'Betreuer',
    },
    dateRange: {
      en: 'Date range',
      de: 'Zeitraum',
    },
    createdOn: {
      en: 'Created on',
      de: 'Erstellt am',
    },
    createdBy: {
      en: 'Created by',
      de: 'Erstellt von',
    },
  },
  filters: {
    propertyAdministrationTypes: {
      en: 'Administration type',
      de: 'Verwaltungsart',
    },
    administrationTypeValues: {
      WEG: {
        en: 'Wohnungseigentum (WEG)',
        de: 'Wohnungseigentum (WEG)',
      },
      MV: {
        en: 'Mietverwaltung (MV)',
        de: 'Mietverwaltung (MV)',
      },
      SEV_MV: {
        en: 'SEV without WEG connection',
        de: 'SEV ohne WEG-Verbindung',
      },
      SEV: {
        en: 'SEV with WEG connection',
        de: 'SEV mit WEG-Verbindung',
      },
    },
    minCreated: {
      en: 'Creation date from',
      de: 'Erstellungsdatum von',
    },
    maxCreated: {
      en: 'Creation date until',
      de: 'Erstellungsdatum bis',
    },
  },
  profitAndLossReportView: {
    deleteModal: {
      deleteButton: {
        en: 'Delete',
        de: 'Löschen',
      },
      content: {
        en: 'You cannot undo the action after deleting the profit and loss report. However, you can generate a report again.',
        de: 'Das Löschen der Eigentümerbericht kann nicht mehr rückgängig gemacht werden. Sie können jedoch erneut einen Bericht erstellen.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      title: {
        en: 'Delete report',
        de: 'Löschen Eigentümerbericht',
      },
    },
    edit: {
      en: 'Edit',
      de: 'Bearbeiten',
    },
    prepareSend: {
      en: 'Preview and send',
      de: 'Vorschau & Versenden',
    },
    checkMessages: {
      en: 'View messages',
      de: 'Nachrichten ansehen',
    },
    retry: {
      en: 'Recreate',
      de: 'Neu erstellen',
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    states: {
      GENERATING: {
        en: 'Generating',
        de: 'Wird erstellt',
      },
      DRAFT: {
        en: 'Draft',
        de: 'Entwurf',
      },
      SENT: {
        en: 'Sent',
        de: 'Gesendet',
      },
      FAILED: {
        en: 'Failed',
        de: 'Fehlgeschlagen',
      },
    },
  },
  notifications: {
    loadViewError: {
      en: 'Failed to load profit and loss report.',
      de: 'Fehler beim Laden der Eigentümerbericht.',
    },
    notFoundError: {
      en: 'Profit and loss report not found.',
      de: 'Eigentümerbericht wird nicht gefunden.',
    },
    loadError: {
      en: 'Failed to load profit and loss reports.',
      de: 'Fehler beim Laden der Eigentümerberichten.',
    },
    propertyLoadError: {
      en: 'Cannot load properties.',
      de: 'Objekte können nicht geladen werden.',
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie fortfahren?',
    },
    loadAccountBalancesError: {
      en: 'Failed to load data for report.',
      de: 'Fehler beim Laden der Daten für Eigentümerbericht.',
    },
    recalculateError: {
      en: 'P&L report could not be recalculated.',
      de: 'Eigentümerbericht konnte nicht neu berechnet werden.',
    },
    recalculateSuccess: {
      en: 'P&L report successfully recalculated.',
      de: 'Eigentümerbericht wurde erfolgreich neu berechnet.',
    },
    deletionSuccess: {
      en: 'Profit and loss report deleted successfully.',
      de: 'Eigentümerbericht wurde erfolgreich gelöscht.',
    },
    deletionError: {
      en: 'Failed to delete profit and loss report.',
      de: 'Eigentümerbericht konnten nicht gelöscht werden.',
    },
    generatingReportLoadingMessage: {
      title: {
        en: 'Generating report…',
        de: 'Abrechnung wird erstellt…',
      },
      subtitle: {
        en: 'The report may take a few minutes to generate. In the meantime, you can leave and come back later.',
        de: 'Da die Erstellung der Abrechnung einige Minuten dauern kann, können Sie diese Seite verlassen und später zurückkehren, um die Ergebnisse zu überprüfen.',
      },
    },
    generationError: {
      en: 'Report generation failed.',
      de: 'Die Abrechnung konnte nicht erstellt werden.',
    },
    generationErrorMessage: {
      en: 'Report generation failed. Please try to recreate or',
      de: 'Die Abrechnung konnte nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder',
    },
    contactSupport: {
      en: 'contact support.',
      de: 'kontaktieren Sie unser Support Team.',
    },
  },
  report: {
    header: {
      title: {
        en: 'P&L Report',
        de: 'Eigentümerbericht',
      },
      status: {
        en: 'UNSAVED',
        de: 'NICHT GESPEICHERT',
      },
      closeButton: {
        en: 'Close',
        de: 'Schließen',
      },
      recalculateButton: {
        en: 'Recalculate',
        de: 'Neu berechnen',
      },
      saveButton: {
        en: 'Save',
        de: 'Speichern',
      },
    },
    form: {
      title: {
        en: 'General data',
        de: 'Allgemein',
      },
      labels: {
        select: {
          en: 'Property',
          de: 'Objekt',
        },
        starteDate: {
          en: 'Start date',
          de: 'Startdatum',
        },
        endDate: {
          en: 'End date',
          de: 'Enddatum',
        },
        docTitle: {
          en: 'Document title',
          de: 'Titel des Dokuments',
        },
        calculate: {
          en: 'Calculate',
          de: 'Berechnen',
        },
        recalculate: {
          en: 'Recalculate',
          de: 'Neu berechnen',
        },
      },
      sectionSummary: {
        select: {
          en: 'Property',
          de: 'Objekt',
        },
        starteDate: {
          en: 'Start date',
          de: 'Startdatum',
        },
        endDate: {
          en: 'End date',
          de: 'Enddatum',
        },
        docTitle: {
          en: 'Document title',
          de: 'Titel des Dokuments',
        },
      },
      validation: {
        required: {
          en: 'Field is required',
          de: 'Feld ist erforderlich',
        },
        invalidDateRange: {
          en: 'Invalid date range',
          de: 'Ungültiger Datumsbereich',
        },
      },
    },
    sections: {
      incomeSection: {
        title: {
          en: 'Income',
          de: 'Einnahmen',
        },
        rentIncomeTitle: {
          en: 'Rent income',
          de: 'Mieteinnahmen',
        },
        totalRentIncome: {
          en: 'Total Rent Income (debit)',
          de: 'Summe Mieteinnahmen (Soll)',
        },
        totalRentIcomeActual: {
          en: 'Total Rent income (actual)',
          de: 'Summe Mieteinnahmen (Ist)',
        },
        otherIncomeTitle: {
          en: 'Other Income',
          de: 'Sonstige Einnahmen',
        },
        totalOtherIncome: {
          en: 'Total Other Income',
          de: 'Summe sonstige Einnahmen',
        },
        tenantPaymentDeficit: {
          en: 'Tenant payment deficit (-) / Tenant overpayment (+)',
          de: 'Summe Mietausfälle (-) / Zusätzliche Mietzahlungen (+)',
        },
        fromThisWithVat: {
          en: 'with VAT',
          de: 'davon USt.',
        },
        columns: {
          accountCode: {
            en: 'Account code',
            de: 'Konto-Nr',
          },
          accountName: {
            en: 'Account name',
            de: 'Kontobezeichnung',
          },
          netAmount: {
            en: 'Net',
            de: 'Netto',
          },
          vat: {
            en: 'VAT',
            de: 'USt.',
          },
          currentPeriod: {
            en: 'Current time period',
            de: 'Aktuelle Periode',
          },
        },
      },
      openTenantAccountsSection: {
        title: {
          en: 'Open tenant accounts',
          de: 'Offene Posten Mieter - Gesamt',
        },
        firstSummary: {
          en: 'Total of open rental payments',
          de: 'Summe offene Posten Mietzahlungen',
        },
        secondSummary: {
          en: 'Total of open amounts',
          de: 'Summe offene Posten gesamt',
        },
        explanation: {
          en: 'Sum of all deficits / balances across all tenants',
          de: 'Summe aller Rückstände / Guthaben über alle Mieter hinweg',
        },
        columns: {
          accountCode: {
            en: 'Account code',
            de: 'Konto-Nr',
          },
          accountName: {
            en: 'Account name',
            de: 'Kontobezeichnung',
          },
          startBalance: {
            en: 'Start balance',
            de: 'Anfangs - Saldo',
          },
          debit: {
            en: 'Debit',
            de: 'Soll',
          },
          credit: {
            en: 'Credit',
            de: 'Ist',
          },
          balanceWithinPeriod: {
            en: 'Change in the current period',
            de: 'Veränderung Aktuelle Periode',
          },
          endBalance: {
            en: 'End balance',
            de: 'End - Saldo',
          },
        },
      },
      propertyOwnerPaymentsSection: {
        title: {
          en: 'Property owner payments',
          de: 'Zahlungen Objekteigentümer',
        },
        columns: {
          ownerName: {
            en: 'Owner',
            de: 'Eigentümer',
          },
          deposits: {
            en: 'Deposits',
            de: 'Einzahlungen',
          },
          withdrawals: {
            en: 'Withdrawals',
            de: 'Entnahmen',
          },
          balance: {
            en: 'Balance',
            de: 'Saldo',
          },
        },
        summaryRow: {
          title: {
            en: 'Total Payments Owners',
            de: 'Summe Zahlungen Objekteigentümer',
          },
        },
      },
      resultSection: {
        title: {
          en: 'Result',
          de: 'Ergebnis',
        },
        debitResult: {
          en: 'Result (debit)',
          de: 'Ergebnis (Soll)',
        },
        actualResult: {
          en: 'Result (actual)',
          de: 'Ergebnis (Ist)',
        },
        calculatedChange: {
          en: 'Calculated change in the capital stock',
          de: 'Errechnete Veränderung Kapitalbestand',
        },
        rentIncomeTotal: {
          en: 'Rent income total',
          de: 'Summe Mieteinnahmen (Soll)',
        },
        expenseTotal: {
          en: 'Expense total',
          de: 'Summe Ausgaben',
        },
        otherIncomeTotal: {
          en: 'Other income total',
          de: 'Summe sonstige Einnahmen',
        },
        openTenantAmount: {
          en: 'Open tenant claims: overpayments (+) / deficits (-)',
          de: 'Offene Mietforderungen: Überzahlungen (+)/entstandene Rückstände (-)',
        },
        deposits: {
          en: 'Deposits (Actual)',
          de: 'Einzahlungen (Ist)',
        },
        withdrawals: {
          en: 'Withdrawals (Actual)',
          de: 'Entnahmen (Ist)',
        },
        calculatedChangeBeforeDeferrals: {
          en: 'Changes before balance account bookings',
          de: 'Ergebnis vor Bilanzkontenbewegungen',
        },
        noBalanceAccoutnBookings: {
          en: 'No balance account bookings',
          de: 'Keine Buchungen vorhanden. ',
        },
        rentIncomeVatTotal: {
          en: 'Rent income VAT',
          de: 'Aus Mieten USt.',
        },
        otherIncomeVatTotal: {
          en: 'Other income VAT',
          de: 'Aus sonstigen Erträgen USt.',
        },
        eligibleVatTotal: {
          en: 'eligible VAT from expenses',
          de: 'abzgl. anrechenbare USt. (Vorsteuer)',
        },
        totalCollected: {
          en: 'Total VAT collected',
          de: 'Summe eingenommene Umsatzsteuer',
        },
        liabilitiesToTaxOffice: {
          en: 'Liability to tax office',
          de: 'Verbindlichkeit gegenüber Finanzamt',
        },
        columns: {
          position: {
            en: 'Position',
            de: 'Position',
          },
          totalAmount: {
            en: 'Total amount',
            de: 'Gesamtbetrag',
          },
        },
        vatColumns: {
          vatToBePaid: {
            en: 'VAT to be paid',
            de: 'Abzuführende Umsatzsteuer',
          },
          amount: {
            en: 'Amount',
            de: 'Betrag',
          },
        },
      },
      bankAccountsSection: {
        title: {
          en: 'Development of bank accounts / cash',
          de: 'Entwicklung der Geldkonten / Barvermögen',
        },
        columns: {
          account: {
            en: 'Account',
            de: 'Sachkonto',
          },
          startBalance: {
            en: 'Start balance',
            de: 'Anfangs - Saldo',
          },
          debit: {
            en: 'Incoming',
            de: 'Zuflüsse',
          },
          credit: {
            en: 'Outgoing',
            de: 'Abflüsse',
          },
          balanceWithinPeriod: {
            en: 'Change in the current period',
            de: 'Veränderung Aktuelle Periode',
          },
          endBalance: {
            en: 'End balance',
            de: 'End - Saldo',
          },
        },
        datasourceNames: {
          depositBankAccounts: {
            en: 'Sum deposit bank accounts',
            de: 'Summe Kautionskonten',
          },
        },
        summary: {
          en: 'bank accounts summary',
          de: 'Summe Objektkonten',
        },
      },
      expenseSection: {
        title: {
          en: 'Expenses',
          de: 'Ausgaben',
        },
        applicableExpensesWka: {
          en: 'Applicable Expenses - Heating cost (WKA)',
          de: 'Umlagefähige Beträge - Wärmekostenabrechnung',
        },
        otherApplicableExpenses: {
          en: 'Other applicable Expenses',
          de: 'Umlagefähige Beträge - Sonstige',
        },
        notApplicableExpenses: {
          en: 'Not applicable expenses',
          de: 'Nicht umlagefähige Beträge',
        },
        subtotal: {
          en: 'Subtotal',
          de: 'Zwischensumme',
        },
        totalOfExpenses: {
          en: 'Total of all expenses',
          de: 'Summe Ausgaben',
        },
        totalOfExpensesSummary: {
          en: 'Total of all expenses',
          de: 'Summe Ausgaben',
        },
        columns: {
          accountCode: {
            en: 'Account code',
            de: 'Konto-Nr',
          },
          accountName: {
            en: 'Account name',
            de: 'Kontobezeichnung',
          },
          netAmount: {
            en: 'Net',
            de: 'Netto',
          },
          vat: {
            en: 'VAT',
            de: 'USt.',
          },
          eligibleVat: {
            en: 'Eligible VAT',
            de: 'USt. Anrechenb.',
          },
          currentPeriod: {
            en: 'Current time period',
            de: 'Aktuelle Periode',
          },
        },
        totalColumns: {
          position: {
            en: 'Position',
            de: 'Position',
          },
          totalAmount: {
            en: 'Total amount',
            de: 'Gesamtbetrag',
          },
        },
      },
      assetReportSection: {
        title: {
          en: 'Asset report',
          de: 'Vermögensbericht',
        },
        columns: {
          accountCode: {
            en: 'Account code',
            de: 'Konto-Nr',
          },
          accountName: {
            en: 'Account name',
            de: 'Kontobezeichnung',
          },
          active: {
            en: 'Active',
            de: 'Aktiva',
          },
          passive: {
            en: 'Passive',
            de: 'Passiva',
          },
        },
        datasetNames: {
          tenantBalances: {
            en: 'Tenant balances',
            de: 'Mietersalden',
          },
          depositPaymentDeficits: {
            en: 'Deposit payment deficits',
            de: 'Offene Kautionsforderungen',
          },
          depositLiabilities: {
            en: 'Deposit liabilities',
            de: 'Kautionsverbindlichkeiten',
          },
        },
        summaryPropertyAccounts: {
          en: 'Summary property accounts',
          de: 'Saldo Objektkonten',
        },
        summaryDeposits: {
          en: 'Summary deposits',
          de: 'Saldo Mietkautionen',
        },
        summary: {
          en: 'Summary',
          de: 'Gesamtsaldo',
        },
      },
      annexSection: {
        title: {
          en: 'Tenant list',
          de: 'Mieterliste',
        },
        vacancy: {
          en: 'Vacancy',
          de: 'Leerstand',
        },
        columns: {
          unitNrSharingDeclaration: {
            en: 'Unit',
            de: 'Einheit',
          },
          tenantName: {
            en: 'Tenant',
            de: 'Mieter',
          },
          rentPeriod: {
            en: 'Rental period (from-until)',
            de: 'Mietzeitraum (von-bis)',
          },
          startBalance: {
            en: 'Starting Balance',
            de: 'Anfangssaldo',
          },
          debit: {
            en: 'Debit',
            de: 'Soll',
          },
          debitBrut: {
            en: 'Debit',
            de: 'Soll Brutto',
          },
          credit: {
            en: 'Credit',
            de: 'Ist',
          },
          creditBrut: {
            en: 'Credit',
            de: 'Ist Brutto',
          },
          nominalRent: {
            en: 'thereof nominal rent',
            de: 'davon Sollmiete (Ist)',
          },
          nominalRentNet: {
            en: 'thereof nominal rent',
            de: 'davon Sollmiete Netto (Ist)',
          },
          opsCostSettlement: {
            en: 'thereof ops cost',
            de: 'davon Nebenkosten (Ist)',
          },
          opsCostSettlementNet: {
            en: 'thereof ops cost',
            de: 'davon Nebenkosten Netto (Ist)',
          },
          additionalIncome: {
            en: 'thereof additional income',
            de: 'davon zusätzlich. Mietein. (Ist)',
          },
          additionalIncomeNet: {
            en: 'thereof additional income',
            de: 'davon zusätzlich. Mietein. Netto (Ist)',
          },
          directCost: {
            en: 'thereof direct Costs',
            de: 'davon Dir. Belastungen (Ist)',
          },
          directCostNet: {
            en: 'thereof direct Costs',
            de: 'davon Dir. Belastungen Netto (Ist)',
          },
          vat: {
            en: 'thereof VAT',
            de: 'davon USt. (Ist)',
          },
          deposit: {
            en: 'Deposit',
            de: 'Mietkaution',
          },
          totalChange: {
            en: 'Total change',
            de: 'Verände-rung Gesamt',
          },
          endBalance: {
            en: 'End Balance',
            de: 'Endsaldo',
          },
        },
        total: {
          en: 'Total',
          de: 'Summe',
        },
      },
      emptyTableMessage: {
        en: 'Within the reporting timerange no bookings were generated for this position.',
        de: 'Für diese Position wurden im Zeitraum des Berichts keine Buchungen erstellt.',
      },
    },
    notifications: {
      errorCreatingReport: {
        en: 'Failed to create report.',
        de: 'Eigentümerbericht konnte nicht erstellt werden.',
      },
    },
  },
};
