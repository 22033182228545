import { HeatingCentersApi, HeatingCenterUnitsApi } from 'api/public';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import { useHeatingCenterDataExchangeActions } from '../stores/DataExchangeSection/selectorsHeatingCenterDataExchange';
import { useHeatingCenterCommonDataActions } from '../stores/CommonData/selectorsHeatingCenterCommonData';
import { translationsHeatingCenterEditor } from '../translationsHeatingCenterEditor';
import { useHeatingCenterGeneralDataActions } from '../stores/GeneralDataSection/selectorsHeatingCenterGeneralData';

/* Tried using `2147483647` but the BE threw an error saying that the page size cannot be a negative number */
export const HC_UNITS_MAX_PAGE_SIZE = 999999;


export const useLoadHeatingCenterData = () => {
  const { tl } = useContext(LanguageContext);
  const { publicApiConfiguration } = useContext(AuthContext);
  const heatingCentersApi = new HeatingCentersApi(publicApiConfiguration('public'));
  const heatingCenterUnitsApi = new HeatingCenterUnitsApi(publicApiConfiguration('public'));

  const { onChangeLoadedData } = useHeatingCenterCommonDataActions();
  const { onInitializeDataExchangeTableFromHeatingCenterUnits } = useHeatingCenterDataExchangeActions();
  const { onInitializeFormDataFromHeatingCenterDto } = useHeatingCenterGeneralDataActions();

  const onLoadHeatingCenterUnits = (parsedHcId: number) => {
    onChangeLoadedData('heatingCenterUnits', v => v.startLoading());

    heatingCenterUnitsApi.findByFilterUsingGET({ heatingCenterIds: [parsedHcId], size: HC_UNITS_MAX_PAGE_SIZE })
      .then((response) => {
        onChangeLoadedData('heatingCenterUnits', v => v.load(response.content));
        onInitializeDataExchangeTableFromHeatingCenterUnits(response.content);
      })
      .catch((err) => {
        console.error(err);
        onChangeLoadedData('heatingCenterUnits', v => v.failed(err));
        showNotification({
          type: 'error',
          message: tl(translationsHeatingCenterEditor.initializationNotifications.loadingHeatingCenterUnitsErrorNotification),
        });
      });
  };

  const onLoadHeatingCenter = (parsedHcId: number) => {
    heatingCentersApi.findHeatingCentersByFilterUsingGET({ ids: [parsedHcId] })
      .then((resp) => {
        if (resp?.content?.length !== 1) {
          throw new Error('Invalid heating center response.');
        }

        const hc = resp.content[0];
        onChangeLoadedData('heatingCenter', hc);
        onInitializeFormDataFromHeatingCenterDto(hc);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: tl(translationsHeatingCenterEditor.initializationNotifications.loadHeatingCenterErrorNotification),
          type: 'error',
        });
      });
  };


  return { onLoadHeatingCenterUnits, onLoadHeatingCenter };
};
