import { useContext } from 'react';
import { useStore } from 'zustand';
import { HeatingCenterGeneralDataStore } from './interfacesHeatingCenterGeneralData';
import { HeatingCenterGeneralDataStoreContext } from './storeHeatingCenterGeneralData';

// ! do not export !
const useHeatingCenterGeneralDataStore = <T, >(selector: (state: HeatingCenterGeneralDataStore) => T): T => {
  const store = useContext(HeatingCenterGeneralDataStoreContext);

  if (!store) {
    throw new Error('Missing HeatingCenterGeneralDataStoreProvider.');
  }

  return useStore(store, selector);
};

/* Dirty, Validation */
export const useHeatingCenterGeneralDataDirty = () => useHeatingCenterGeneralDataStore(state => state.isDirty);
export const useHeatingCenterValidationStates = () => useHeatingCenterGeneralDataStore(state => state.validationStates);

/* General Information */
export const useHeatingCenterGeneralInformation = () => useHeatingCenterGeneralDataStore(state => state.generalInformation);

/* Reporting Settings */
export const useHeatingCenterReportingSettings = () => useHeatingCenterGeneralDataStore(state => state.reportingSettings);


/* Actions */
export const useHeatingCenterGeneralDataActions = () => useHeatingCenterGeneralDataStore(state => state.actions);
