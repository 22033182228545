import { Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { matchPath, Prompt, useLocation } from 'react-router';
import { useShowCorrectionalBookings } from 'pages/EconomicPlan/services/useShowCorrectionalBookings';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import { WKA_CREATE_PATH, WKA_EDIT_PATH } from 'pages/Wka/routes';
import { LanguageContext } from '../../../contexts/LanguageContext';
import FormSection from '../../../elements/FormElements/FormSection/FormSection';
import FormSidebar from '../../../elements/FormElements/FormSidebar/FormSidebar';
import { translations } from '../../../elements/Translation/translations';
import { generateMenuFromSections } from '../../../lib/Utils';
import './EconomicPlanAccountEditing.scss';
import EditAmountsSection2 from './sections/EditAmountsSection2/EditAmountsSection2';
import PropertyDataSection1 from './sections/PropertyDataSection1';
import ReserveFundsSection3 from './sections/ReserveFundsSection3/ReserveFundsSection3';
import ResultsSection4 from './sections/ResultsSection4';
import CorrectionsSection5 from './sections/CorrectionsSection5';
import useEconomicPlanAccountEditing from './services/useEconomicPlanAccountEditing';
import { EconomicPlanAccountEditingHeader } from './EconomicPlanAccountEditingHeader';
import { showNotification } from '../../../lib/Notification';
import { economicPlanTranslations } from '../economicPlanTranslations';
import { useEconomicPlanAccountEditingSectionsManager } from './services/useEconomicPlanAccountEditingSectionsManager';
import SimpleDirtModalContextProvider from '../../../elements/Modals/DirtModal/SimpleDirtModal/SimpleDirtModalContext';

const EconomicPlanAccountEditing = () => {
  const location = useLocation();
  const { tl } = useContext(LanguageContext);
  const { showCorrectionalBookings } = useShowCorrectionalBookings();

  const {
    discardChanges,
    economicPlan,
    addDirt,
    setEconomicPlan,
    economicPlanCreationData,
    isDirty,
  } = useEconomicPlanAccountEditing();

  const sections: any[] = [
    PropertyDataSection1({
      economicPlan,
      economicPlanCreationData,
    }),
    EditAmountsSection2({
      addDirt,
      economicPlan,
      setEconomicPlan,
      economicPlanCreationData,
    }),
    ReserveFundsSection3({
      addDirt,
      economicPlan,
      setEconomicPlan,
      economicPlanCreationData,
    }),
    ResultsSection4({
      economicPlan,
      economicPlanCreationData,
    }),
  ];

  if (showCorrectionalBookings) {
    sections.push(CorrectionsSection5({
      economicPlan,
      economicPlanCreationData,
    }));
  }

  const save = () => (
    sections[openSectionIndex]
      .onSubmit() // also sets dirty to false
      .then(() => {
        showNotification({
          key: 'saveHouseMoneySuccess',
          message: tl(economicPlanTranslations.notifications.saveSuccess.message),
          type: 'success',
        });
      })
  );

  const {
    openSectionIndex,
    handleOpenSectionChange,
    setOpenSectionIndex,
  } = useEconomicPlanAccountEditingSectionsManager(save, discardChanges);

  const saveAndOpenNextSection = () => {
    save()
      .then(() => {
        setOpenSectionIndex(prev => prev + 1);
      });
  };

  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });

  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);


  return (
    <Page className="EconomicPlanEditing">
      <Prompt
        when={isDirty}
        message={loc => (
          !isDirty
          || location.pathname === loc.pathname
          || loc.pathname.includes(WKA_CREATE_PATH)
          || matchPath(loc.pathname, { path: `*${WKA_EDIT_PATH}` })
            ? true
            : tl(translations.elements.prompt))
        }
      />
      <EconomicPlanAccountEditingHeader save={saveAndOpenNextSection} />
      <PageContent className="page-content">
        <FormSidebar
          elements={menuElements}
          openSection={openSectionIndex}
          onOpenSection={handleOpenSectionChange}
          scrollTarget={document.getElementsByClassName('form-content')[0]}
        />
        <div className="form-content">
          <Form id="scrollElement">
            {sections.map((section: any) => (
              <FormSection
                key={section.openingNumber}
                open={(openSectionIndex === (section.openingNumber - 1))}
                addDirt={addDirt}
                onClickHeader={() => handleOpenSectionChange(section.openingNumber - 1)}
                validationErrors={economicPlan.validationErrors}
                {...section}
                onSubmit={saveAndOpenNextSection} // has to be placed after spread operator not to be overridden
              />
            ))}
          </Form>
        </div>
      </PageContent>
    </Page>
  );
};

export default () => (
  <SimpleDirtModalContextProvider>
    <EconomicPlanAccountEditing />
  </SimpleDirtModalContextProvider>
);
