import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { useLoadJsPlugins } from './useLoadJsPlugins';

/**
 * This snippet is responsible for injecting external javascript resources into the html
 *
 * The resources can be installed by enabling an impower connection with app id 17
 *
 * POST /connections
 *
 * { "appId": 17, "externalConfig": "{\"url\":\"https://external.com/some/asset.js\"}"}
 *
 * @returns no content
 */
export const JsPlugins: React.FC = () => {
  const { domainId } = useContext(AuthContext);
  const { loadJsPlugins } = useLoadJsPlugins();

  useEffect(() => {
    // load jsplugins everytime the domain changes
    loadJsPlugins();
  }, [domainId]);
  return (<></>);
};
