import { HeatingCenterDto } from 'api/public';


export enum TempHeatingCenterAreaEnum {
  OVERRIDE = 'OVERRIDE',
  NO_OVERRIDE = 'NO_OVERRIDE',
}

export enum TempCO2DistributionTypeEnum {
  OVERRIDE = 'OVERRIDE',
  NO_OVERRIDE = 'NO_OVERRIDE',
}


export type HeatingCenterGeneralInformation = Pick<HeatingCenterDto,
  'name' |
  'street' |
  'houseNumber' |
  'postalCode' |
  'location' |
  'country' |
  'vatRelevance'
>

export type HeatingCenterReportingSettings = Pick<HeatingCenterDto,
  'vatRelevance' |
  'totalArea' |
  'co2DistributionPercentage' |
  'markNr1' |
  'markNr2' |
  'nonResidentialBuilding' |
  'heatConnectionAfter2023' |
  'heatingDefaultDistributionId' |
  'warmWaterDefaultDistributionId'
> & {
  // local state for controlling whether the inputs are disabled or not
  areaType: TempHeatingCenterAreaEnum,
  co2DistributionType: TempCO2DistributionTypeEnum,
}


export type HeatingCenterGeneralDataValidationStates = {
  [K in keyof HeatingCenterGeneralInformation | keyof HeatingCenterReportingSettings]?: 'NOT_UNIQUE' | 'NOT_EMPTY' | null | undefined;
}


export interface HeatingCenterGeneralDataStore {
  isDirty: boolean,
  generalInformation: HeatingCenterGeneralInformation,
  reportingSettings: HeatingCenterReportingSettings,
  validationStates: HeatingCenterGeneralDataValidationStates,
  actions: {
    onChangeGeneralInformation: <K extends keyof HeatingCenterGeneralInformation>(key: K, value: HeatingCenterGeneralInformation[K]) => void
    onChangeReportingSettings: <K extends keyof HeatingCenterReportingSettings>(key: K, value: HeatingCenterReportingSettings[K]) => void
    onChangeDirty: (isDirty: boolean) => void,
    onChangeValidationStates: (validationStates: HeatingCenterGeneralDataValidationStates) => void,
    onInitializeFormDataFromHeatingCenterDto: (heatingCenterDto: HeatingCenterDto) => void
  }
}
