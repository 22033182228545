import { useContext } from 'react';
import { useStore } from 'zustand';
import { HeatingCenterCommonDataStore } from './interfacesHeatingCenterCommonData';
import { HeatingCenterCommonDataStoreContext } from './storeHeatingCenterCommonData';

// ! do not export !
const useHeatingCenterCommonDataStore = <T, >(selector: (state: HeatingCenterCommonDataStore) => T): T => {
  const store = useContext(HeatingCenterCommonDataStoreContext);

  if (!store) {
    throw new Error('Missing HeatingCenterCommonDataStoreProvider.');
  }

  return useStore(store, selector);
};

/* Loaded Data */
export const useHeatingCenterLoadedData = () => useHeatingCenterCommonDataStore(state => state.loadedData);
export const useHeatingCenterLoading = () => useHeatingCenterCommonDataStore(state => state.loading);

/* Actions */
export const useHeatingCenterCommonDataActions = () => useHeatingCenterCommonDataStore(state => state.actions);
