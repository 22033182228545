import Icon from '@ant-design/icons';
import { SpecialContributionDetailsDtoStateEnum } from 'api/accounting';
import { DocumentDownloadModal } from 'storybook-components/modals/DocumentDownloadModal/DocumentDownloadModal';
import { DropMenu } from 'components/DropMenu/DropMenu';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import { DeletionModal } from 'elements/Modals/DeletionModal/DeletionModal';
import { generateMenuFromSections } from 'lib/Utils';
import moment from 'moment';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import usePoll from 'services/usePoll';
import { AlertArticleLink } from 'storybook-components/feedback/AlertArticleLink/AlertArticleLink';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';
import { Alert } from 'antd';
import { getStateColor } from 'storybook-components/headers/utils';
import { PostingWarningModal } from 'storybook-components/modals/PostingWarningModal/PostingWarningModal';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader, { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';
import Button from '../../../elements/Buttons/Button/Button';
import FormSection from '../../../elements/FormElements/FormSection/FormSection';
import FormSidebar from '../../../elements/FormElements/FormSidebar/FormSidebar';
import DirtModal from '../../../elements/Modals/DirtModal/DirtModal';
import usePostingHGAValidation from '../../../storybook-components/modals/PostingWarningModal/usePostingHGAValidation';
import { translations } from '../translations';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import Section3 from './sections/Section3/Section3';
import SpecialContributionCreationContextProvider, {
  SpecialContributionCreationContext,
} from './services/SpecialContributionCreationContext';
import { useDeleteSpecialContribution } from './services/useDeleteSpecialContribution';
import { useDownloadSpecialContributionDocument } from './services/useDownloadSpecialContributionDocument';
import { useExecuteSpecialContribution } from './services/useExecuteSpecialContribution';
import { useLoadSpecialContribution } from './services/useLoadSpecialContribution';
import { useSendSpecialContribution } from './services/useSendSpecialContribution';
import './SpecialContributionCreation.scss';

const SpecialContributionCreation = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation<{ openSectionIndex: number | undefined }>();
  const history = useHistory();
  const [openSectionIndex, setOpenSectionIndex] = useState<number>(location?.state?.openSectionIndex || 0);
  const { specialContributionId: specialContributionIdString } = useParams<{ specialContributionId: string }>();

  const {
    specialContribution, dirty, loading, setDirty, setSpecialContribution, setLoading,
  } = useContext(SpecialContributionCreationContext);

  const {
    onRegenerateSpecialContribution,
    hasDocuments,
    documentsInGeneration,
    hasFailedDocuments,
    fetchDocuments,
    pollDocumentGenerationStatus,
    messagesExist,
    documents,
    documentModalVisible,
    setDocumentModalVisible,
    startLoading,
    stopLoading,
    messagesGenerating,
  } = useDownloadSpecialContributionDocument();
  const { loadSpecialContribution } = useLoadSpecialContribution();
  const { deleteSpecialContribution } = useDeleteSpecialContribution();
  const { executeSpecialContribution } = useExecuteSpecialContribution();
  const { onSend, onCheckMessages } = useSendSpecialContribution();

  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState<boolean>(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState<boolean>(false);

  const date = specialContribution?.dueDate ? moment(specialContribution.dueDate, 'YYYY-MM-DD') : undefined;
  const {
    visible,
    onClickReview,
    onClickProceed,
    onClickCancel,
    isHgaClosed,
    showModal: showHgaClosedModal,
  } = usePostingHGAValidation({ propertyId: specialContribution?.propertyId, date });

  const afterSaveModalDiscardsAction = useRef(() => {
  });
  const afterSaveModalSavesAction = useRef(() => {
  });
  const dirtyModalSectionIndex = useRef<number>(0);

  const specialContributionId = parseInt(specialContributionIdString || '0', 10);

  useEffect(() => {
    if (specialContributionId) {
      loadSpecialContribution(specialContributionId);
      fetchDocuments(specialContributionId);
    }
  }, [specialContributionId]);

  usePoll({
    shouldPoll: documentsInGeneration,
    pollFunction: () => pollDocumentGenerationStatus(specialContributionId),
    timeoutSeconds: 5000,
  });

  const openSectionAfterModalCloses = () => {
    if (openSectionIndex !== dirtyModalSectionIndex.current) {
      setOpenSectionIndex(dirtyModalSectionIndex.current);
    }
  };

  const handleOpenSectionChange = (nextSectionIndex: number): void => {
    if (dirty) {
      if (openSectionIndex === 0) {
        afterSaveModalDiscardsAction.current = () => {
          setSpecialContribution(undefined);
          setIsSaveModalVisible(false);
          setDirty(false);
        };
      } else {
        afterSaveModalDiscardsAction.current = () => {
          reloadSpecialContributionOnDiscard();
          openSectionAfterModalCloses();
        };
      }
      dirtyModalSectionIndex.current = nextSectionIndex;
      afterSaveModalSavesAction.current = () => openSectionAfterModalCloses();
      setIsSaveModalVisible(true);
    } else {
      if (openSectionIndex === nextSectionIndex) {
        // if the clicked section is the current one, close the sections
        nextSectionIndex = -1;
      }
      setOpenSectionIndex(nextSectionIndex);
    }
  };

  const sections = [
    Section1(setDirty, setLoading, specialContribution, setSpecialContribution),
    Section2(setDirty, setLoading, setOpenSectionIndex, specialContribution, setSpecialContribution),
    Section3(setDirty, setLoading, setOpenSectionIndex, specialContribution, setSpecialContribution),
  ];

  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });

  const goBack = () => {
    history.push('/special-contribution');
  };

  const saveSection = () => {
    if (openSectionIndex > 0 || (openSectionIndex === 0 && !specialContributionId)) {
      return sections[openSectionIndex].onSubmit(false);
    }
    return Promise.resolve();
  };

  const onExecute = () => {
    saveSection()
      .then(() => {
        if (isHgaClosed) {
          showHgaClosedModal(() => executeSpecialContribution(specialContributionId));
        } else {
          executeSpecialContribution(specialContributionId);
        }
      });
  };

  const dropmenuOptions = [
    {
      label: tl(translations.specialContribution.regenerateButton),
      onClick: () => {
        if (specialContribution?.state === SpecialContributionDetailsDtoStateEnum.DRAFT) {
          saveSection().then(() => onRegenerateSpecialContribution(specialContributionId));
        } else {
          onRegenerateSpecialContribution(specialContributionId);
        }
      },
      disabled: !specialContribution?.id || documentsInGeneration || messagesGenerating,
      tooltipText: messagesGenerating ? tl(translations.specialContribution.messagesGeneratingTooltip) : undefined,
    },
    {
      label: tl(translations.specialContribution.downloadButton),
      onClick: () => setDocumentModalVisible(true),
      disabled: !hasDocuments || documentsInGeneration || hasFailedDocuments,
    }];

  if (specialContribution?.state === SpecialContributionDetailsDtoStateEnum.DRAFT || messagesExist) {
    dropmenuOptions.splice(1, 0, {
      label: tl(translations.specialContribution.send),
      onClick: onSend,
      disabled: !specialContribution?.id || !hasDocuments || hasFailedDocuments,
    });
  }

  if (specialContribution?.state === SpecialContributionDetailsDtoStateEnum.DRAFT && messagesExist) {
    dropmenuOptions.splice(1, 0, {
      label: tl(translations.specialContribution.checkMessages),
      onClick: onCheckMessages,
      disabled: !specialContribution?.id,
    });
  }

  dropmenuOptions.push({
    label: tl(translations.specialContribution.deleteButton),
    onClick: () => setIsDeletionModalVisible(true),
    disabled: specialContribution?.state === SpecialContributionDetailsDtoStateEnum.DONE,
  });

  const headerButtons = () => (
    <>
      <Button
        type="text"
        onClick={() => {
          if (dirty) {
            setIsSaveModalVisible(true);
            afterSaveModalDiscardsAction.current = goBack;
            afterSaveModalSavesAction.current = goBack;
          } else {
            goBack();
          }
        }}
        disabled={false}
        loading={loading}
      >
        {tl(translations.specialContribution.closeButton)}
      </Button>
      <DropMenu
        options={dropmenuOptions}
        disabled={false}
      >
        <Icon component={ICONS.menuDots} />
      </DropMenu>
      {specialContribution?.state === SpecialContributionDetailsDtoStateEnum.DRAFT || specialContribution?.id === undefined ? (
        <Button
          type="primary"
          loading={loading}
          onClick={onExecute}
          disabled={specialContribution?.id === undefined}
        >
          {tl(translations.specialContribution.executeButton)}
        </Button>
      ) : (
        <Button
          type="primary"
          loading={loading}
          onClick={messagesExist ? onCheckMessages : onSend}
        >
          {messagesExist ? tl(translations.specialContribution.checkMessages) : tl(translations.specialContribution.send)}
        </Button>
      )
      }
    </>
  );

  const reloadSpecialContributionOnDiscard = () => {
    loadSpecialContribution(specialContributionId).then(() => {
      setIsSaveModalVisible(false);
      setDirty(false);
    });
  };

  const handleDirtyModalSave = () => {
    saveSection().then(() => {
      setIsSaveModalVisible(false);
      setDirty(false);
      afterSaveModalSavesAction.current();
    });
  };

  return (
    <Page className="SpecialContributionCreation">
      <PageHeader
        title={tl(translations.specialContribution.creationHeader.title)}
        tagContent={specialContribution?.state ? tl(translations.specialContribution.creationHeader.state[specialContribution.state]) : ''}
        tagColor={getStateColor(specialContribution?.state) as PageHeaderTagColor}
        subtitle={specialContribution?.statement}
        rightSideComponent={headerButtons()}
        showLogo
      />
      <PageContent className="page-content">
        <div className="scroll-element">
          {
            hasFailedDocuments
            && (
              <Alert
                type="warning"
                showIcon
                icon={<Icon component={ICONS.warning} />}
                message={(
                  <AlertArticleLink
                    label={tl(translations.specialContribution.generationErrorMessage)}
                    content={tl(translations.specialContribution.contactSupport)}
                    url={articleUrls.contactSupport}
                    showIcon={false}
                  />
                )}
              />
            )
          }
          {sections.map((section: any) => (
            <FormSection
              key={section.openingNumber}
              open={(openSectionIndex === section.openingNumber)}
              onClickHeader={() => handleOpenSectionChange(section.openingNumber)}
              {...section}
              buttonDisabled={section.buttonDisabled || !dirty}
              buttonLoading={loading}
              addDirt={() => {
                setDirty(true);
              }}
            />
          ))}
        </div>
        <FormSidebar
          elements={menuElements}
          openSection={openSectionIndex}
          onOpenSection={handleOpenSectionChange}
          scrollTarget={document.getElementsByClassName('scroll-element')[0]}
        />
      </PageContent>
      <DeletionModal
        onClickOk={() => deleteSpecialContribution(specialContributionId).then(goBack)}
        onClickCancel={() => setIsDeletionModalVisible(false)}
        visible={isDeletionModalVisible}
        loading={loading}
      />
      <DirtModal
        visible={isSaveModalVisible}
        onCancel={() => setIsSaveModalVisible(false)}
        onSave={handleDirtyModalSave}
        onDiscard={afterSaveModalDiscardsAction.current}
        loading={loading}
      />
      <PostingWarningModal
        visible={visible}
        onCancel={onClickCancel}
        onProceed={onClickProceed}
        onReview={onClickReview}
      >
        {tl(translations.specialContribution.creationHeader.specialContribution)}
      </PostingWarningModal>
      <DocumentDownloadModal
        visible={documentModalVisible}
        onChangeVisible={setDocumentModalVisible}
        loading={loading}
        documents={documents}
        startLoading={startLoading}
        stopLoading={stopLoading}
      />
    </Page>
  );
};

export default () => (
  <SpecialContributionCreationContextProvider>
    <SpecialContributionCreation />
  </SpecialContributionCreationContextProvider>
);
