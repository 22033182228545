import { PropsWithChildren, createContext, useState } from 'react';
import { StoreApi, createStore } from 'zustand';
import DEFAULT_DATA from 'lib/data';
import { HeatingCenterUnitDto } from 'api/public';
import { DistributionSetDto } from 'api/accounting';
import { HeatingCenterCommonDataStore } from './interfacesHeatingCenterCommonData';
import { onChangeLoadedData, onChangeLoading } from './actionsHeatingCenterCommonData';


const createHeatingCenterCommonDataStore = () => (
  createStore<HeatingCenterCommonDataStore>(set => ({
    loading: false,
    isDirty: false,
    loadedData: {
      property: undefined,
      distributionSets: DEFAULT_DATA<DistributionSetDto[]>([]),
      heatingCenter: undefined,
      heatingCenterUnits: DEFAULT_DATA<HeatingCenterUnitDto[]>([]),
    },
    validationStates: {},
    actions: {
      onChangeLoadedData: (...args) => onChangeLoadedData(set, ...args),
      onChangeLoading: (...args) => onChangeLoading(set, ...args),
    },
  }))
);


export const HeatingCenterCommonDataStoreContext = createContext<StoreApi<HeatingCenterCommonDataStore> | null>(null);


export const HeatingCenterCommonDataStoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [store] = useState(createHeatingCenterCommonDataStore);

  return (
    <HeatingCenterCommonDataStoreContext.Provider value={store}>
      {children}
    </HeatingCenterCommonDataStoreContext.Provider>
  );
};
