import { useLocation } from 'react-router';
import { useState } from 'react';
import { EconomicPlanDtoTypeEnum } from '../../api/accounting';
import EconomicPlanAccountEditing from './EconomicPlanAccountEditing/EconomicPlanAccountEditing';
import { EconomicPlanHouseMoney } from './EconomicPlanHouseMoney/EconomicPlanHouseMoney';

export default () => {
  const { search } = useLocation();

  /**
   * The `type` must only be initialized once, at first render. Otherwise, when we open an
   * additional overlay (e.g. WKA editor), then the search parameter will become null and
   * so this editor would unmount (it would return null) and we'd lose any changes made.
   */
  const [type] = useState(() => new URLSearchParams(search).get('type'));


  if (type === EconomicPlanDtoTypeEnum.UNIT_FIGURES) {
    return <EconomicPlanHouseMoney />;
  }
  if (type === EconomicPlanDtoTypeEnum.ACCOUNT_DISTRIBUTION) {
    return (<EconomicPlanAccountEditing />);
  }

  return null;
};
