import Icon from '@ant-design/icons/lib/components/Icon';
import {
  Checkbox, Tag, Tooltip,
} from 'antd';
import { ICONS } from 'components/icons';
import SelectInput, { SelectInputProps } from 'elements/Inputs/SelectInput/SelectInput';
import { isEmpty, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import './SelectorWithLink.scss';
import PopoverTruncatingText from 'storybook-components/PopoverTruncatingText/PopoverTruncatingText';
import {
  useContext,
  useRef,
} from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { selectorWithLinkTranslation } from './translations';

interface SelectInputWithLinkProps extends Omit<SelectInputProps, 'disabled' | 'mode' | 'tagRender' | 'allowClear'> {
    navigationState?: any,
    enableIfHasSelectedValue?: boolean,
    hideClearIcon?: boolean,
    openLinkInNewTab?: boolean,
    // use only if select input has to be disabled based on another fields value
    dangerouslySetDisabled?: boolean,
    allowMultiple?:boolean
    onDropDownRender?: (dropdownContent: React.ReactElement) => React.ReactElement,
  }

export default function SelectInputWithLink(props: SelectInputWithLinkProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    value, onChange, enableIfHasSelectedValue, hideClearIcon, dangerouslySetDisabled,
    openLinkInNewTab, options, allowMultiple, onDropDownRender: dropdownRenderProp,
  } = props;

  const wrapperRef = useRef(null);

  const arrayValue = (isNil(value) || Array.isArray(value)) ? value : [value];

  const tagRender = (tagProps) => {
    const { label: linkLabel, value: tagValue } = tagProps;
    const navigationUrl = options.find(option => option.value === tagValue)?.link;
    if (allowMultiple) {
      return (
        <Tag
          closable
          closeIcon={(
            <Icon
              component={ICONS.cross}
              className="tag-clear-icon"
              onClick={() => handleCheckboxChange(tagValue, false)}
            />
          )}
        >
          {navigationUrl
            ? (
              <Link
                className="link-tag"
                to={navigationUrl}
                target={openLinkInNewTab ? '_blank' : undefined}
              >
                <PopoverTruncatingText
                  containerClassName="SelectorWithLink"
                  value={linkLabel}
                />
              </Link>
            )
            : (
              <PopoverTruncatingText
                containerClassName="SelectorWithLink"
                value={linkLabel}
              />
            )}
        </Tag>
      );
    }


    if (navigationUrl) {
      return (
        <Tooltip title={linkLabel} placement="topLeft">
          <Link
            to={navigationUrl}
            target={openLinkInNewTab ? '_blank' : undefined}
          >
            {linkLabel}
          </Link>
        </Tooltip>
      );
    }

    return <span>{linkLabel}</span>;
  };


  const onClear = () => {
    onChange([]);
  };

  const disabled = dangerouslySetDisabled || (!isEmpty(arrayValue) && !enableIfHasSelectedValue && !allowMultiple);

  const getIcon = () => {
    if (disabled && !hideClearIcon) { return (<Icon component={ICONS.cross} onClick={onClear} className="clear-icon" />); }
    if (!disabled) {
      return (<Icon component={ICONS.triangleDown} className="arrow" />);
    }
    return <span />;
  };

  const handleCheckboxChange = (val, checked) => {
    let updatedValues;
    if (val === 'All') {
      updatedValues = checked ? options.map(option => option.value) : [];
    } else if (checked) {
      // Add value if checked
      updatedValues = arrayValue ? [...arrayValue, val] : [val];
    } else {
      // Remove value if unchecked
      updatedValues = arrayValue?.filter(item => item !== val);
    }

    onChange(updatedValues);
  };


  const onCustomDropDownRenderWithSelectAll = (content) => {
    if (!allowMultiple || isEmpty(options)) {
      /* if not multi-select then don't add the "Select all" option */
      return content;
    }

    return (
      <>
        <div className="select-all-option">
          <Checkbox
            checked={value?.length === options?.length}
            indeterminate={
              value?.length > 0
                && value?.length < options?.length
            }
            onChange={e => handleCheckboxChange('All', e.target.checked)}
          >
            {tl(selectorWithLinkTranslation.all)}
          </Checkbox>
        </div>

        {content}
      </>
    );
  };

  const dropdownRender = isNil(dropdownRenderProp)
    ? onCustomDropDownRenderWithSelectAll
    : content => dropdownRenderProp(onCustomDropDownRenderWithSelectAll(content));

  const getMaxTagPlaceholder = omittedValues => (
    <Tooltip title={(
      <ul className="no-bullet-list">
        {omittedValues.map((omittedValue) => {
          const link = options.find(option => option.value === omittedValue.value)?.link;
          return (
            <li key={omittedValue.value}>
              {link
                ? (
                  <Link
                    target="_blank"
                    to={options.find(option => option.value === omittedValue.value)?.link}
                  >
                    {omittedValue.label}
                  </Link>
                ) : omittedValue.label}
            </li>
          );
        })}
      </ul>
    )}
    >
      +
      {omittedValues.length}
    </Tooltip>
  );


  const customSelectedIcon = ({ isSelected }) => (
    <Checkbox checked={isSelected} />
  );


  return (
    <div className={`SelectorWithLink ${allowMultiple && 'allow-multiple'}`} ref={wrapperRef}>
      <SelectInput
        {...props}
        value={arrayValue}
        mode="multiple"
        tagRender={tagRender}
        disabled={disabled}
        maxTagCount="responsive"
        allowClear={false}
        maxTagPlaceHolder={getMaxTagPlaceholder}
        showSearch={isEmpty(arrayValue) && !enableIfHasSelectedValue}
        dropdownRender={dropdownRender}
        menuItemSelectedIcon={
          allowMultiple ? customSelectedIcon : undefined
        }
        /* required so that dropdown renders in this container, so that styling is applied */
        getPopupContainer={() => wrapperRef.current}
      />
      {!allowMultiple && getIcon()}
    </div>
  );
}

SelectInputWithLink.defaultProps = {
  navigationState: undefined,
  openLinkInNewTab: false,
  enableIfHasSelectedValue: false,
  hideClearIcon: false,
  dangerouslySetDisabled: false,
  allowMultiple: false,
  onDropDownRender: undefined,
};
