import './NumberInputWithSuffix.scss';

import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';

import { Input } from 'antd';

import {
  floatToFormattedString,
  formatCurrencyString,
  formattedStringToFloat,
} from '../../../lib/Utils';

interface Props {
  value: number,
  onChange: (value: number) => void,
  disableInput?: boolean,
  suffix: JSX.Element,
  onClickWrapper: () => void,
  id: string,
  className?: string,
  disabledOnClickWrapper?: boolean,
  isDefaultValue?: boolean,
  defaultValue: string,
  precision?: number,
}

const REGEX_FORMATTED_STRING_OF_ONLY_ZEROS_ = /^(0|\.)+,00$/;

const NumberInputWithSuffix = ({
  value,
  onChange,
  disableInput,
  suffix,
  className,
  onClickWrapper,
  id,
  disabledOnClickWrapper,
  isDefaultValue,
  defaultValue,
  precision,
}: Props) => {
  const [stringValue, setStringValue] = useState<string>(floatToFormattedString(value, precision));


  useEffect(() => {
    setStringValue(floatToFormattedString(value, precision));
  }, [value, precision]);


  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formatAndValidatePrecision = precision || 2;
    // Validation regex, default to precision of 2
    const VALID_NUMBER_REGEX = new RegExp(`^[\\d.]*(,\\d{0,${formatAndValidatePrecision}})?$`);

    if (REGEX_FORMATTED_STRING_OF_ONLY_ZEROS_.test(inputValue)) {
      onChange(0);
      setStringValue(floatToFormattedString(0, formatAndValidatePrecision));
    } else if (VALID_NUMBER_REGEX.test(inputValue)) {
      const formattedValue = formatCurrencyString(inputValue, formatAndValidatePrecision);
      setStringValue(formattedValue);
    }
  };

  const onBlurFunc = () => {
    if (stringValue === '' || formattedStringToFloat(stringValue) === 0) {
      onChange(0);
      setStringValue(floatToFormattedString(0, precision));
    } else {
      setStringValue(currentStringValue => floatToFormattedString(formattedStringToFloat(currentStringValue), precision));
      onChange(formattedStringToFloat(stringValue));
    }
  };

  const onClickWrapperFunc = () => {
    if (!disabledOnClickWrapper) {
      onClickWrapper();
    }
  };

  return (
    <div role="button" className={`${disableInput ? 'NumberInputWithSuffixWrapperDisabled' : 'NumberInputWithSuffixWrapperEnabled'}`} onClick={onClickWrapperFunc} tabIndex={-1}>
      <Input
        id={id}
        value={isDefaultValue ? defaultValue : stringValue}
        size="large"
        inputMode="decimal"
        onChange={onChangeInput}
        onBlur={() => setTimeout(onBlurFunc, 150)}
        onFocus={e => e.target.select()}
        className={`number-input-with-suffix ${className}`}
        suffix={suffix}
        disabled={disableInput}
      />
    </div>
  );
};

NumberInputWithSuffix.defaultProps = {
  disableInput: false,
  disabledOnClickWrapper: false,
  isDefaultValue: false,
  precision: 2,
  className: '',
};
export default NumberInputWithSuffix;
