import React from 'react';
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ContactRoutes } from 'pages/Contacts';
import { ContractRoutes } from 'pages/ContractPage/routes';
import { RenderRoutes } from 'components/RenderRoutes';
import { AnalyticsRoutes } from 'pages/Analytics/routes';
import { ProfitAndLossRoutes } from 'pages/ProfitAndLossReport/routes';
import { MessagesRoutes } from 'pages/Messages/routes';
import { MessageSendingRoutes } from 'pages/MessageSendingPage/routes';
import { UserRoutes } from 'pages/Users/routes';
import { OpsCostReportRoutes } from 'pages/OpsCostReport/routes';
import { WatermarkRoutes } from 'pages/Watermarks';
import Register from 'pages/Register/Register';
import { OrderOpenBalancesRoutes } from 'pages/OrderOpenBalances/routes';
import { DocumentPreviewRoutes } from 'pages/DocumentPreview/routes';
import { DocumentRoutes } from 'pages/Document/routes';
import { history } from 'navigation/History';
import { PrivateRoute } from 'navigation/PrivateRoute';
import Login from 'pages/Login/Login';
import ContextProvider from 'elements/ContextProvider/ContextProvider';
import { DashboardRoutes } from 'pages/Dashboard/routes';
import { PropertyRoutes } from 'pages/Property/routes';
import { InvoiceRoutes } from 'pages/Invoice/routes';
import { BankAccountRoutes } from 'pages/BankAccount/routes';
import { PaymentRoutes } from 'pages/PaymentList/routes';
import { AccountSheetRoutes } from 'pages/AccountSheet/routes';
import { Layout } from 'components/Layout/Layout';
import { DistributionKeyRoutes } from 'pages/DistributionKeys/routes';
import { ResolutionRecordRoutes } from 'pages/ResolutionRecord/routes';
import { HouseMoneySettlementRoutes } from 'pages/HouseMoneySettlement/routes';
import { StandingOrderRoutes } from 'pages/StandingOrder/routes';
import { DirectDebitRoutes } from 'pages/DirectDebitList/routes';
import { BankSettingsRoutes } from 'pages/BankSettings/routes';
import { AccountBalancesRoutes } from 'pages/Account/routes';
import { SerialLetterRoutes } from 'pages/SerialLetters';
import { TransactionImportRoutes } from 'pages/TransactionImports/routes';
import { BankTransactionRoutes } from 'pages/BankTransactions/routes';
import { WkaRoutes } from 'pages/Wka/routes';
import { EconomicPlanRoutes } from 'pages/EconomicPlan/routes';
import { SpecialContributionRoutes } from 'pages/SpecialContribution/routes';
import { ExchangeRoutes } from 'pages/ManualExchange/routes';
import { OwnersMeetingInvitationRoutes } from 'pages/OwnersMeetingInvitation/routes';
import { OwnersMeetingProtocolRoutes } from 'pages/OwnersMeetingProtocol/routes';
import { ImportRoutes } from 'pages/Import/routes';
import { EInvoiceRoutes } from 'pages/EInvoice/routes';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { JsPlugins } from 'components/JsPlugins/JsPlugins';


// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

export default function AppRoutes(): JSX.Element {
  const allRoutes = [
    AccountBalancesRoutes,
    AnalyticsRoutes,
    AccountSheetRoutes,
    BankAccountRoutes,
    BankSettingsRoutes,
    BankTransactionRoutes,
    ContactRoutes,
    ContractRoutes,
    DashboardRoutes,
    DirectDebitRoutes,
    DistributionKeyRoutes,
    EconomicPlanRoutes,
    ExchangeRoutes,
    HouseMoneySettlementRoutes,
    InvoiceRoutes,
    DocumentRoutes,
    ImportRoutes,
    MessageSendingRoutes,
    OrderOpenBalancesRoutes,
    OwnersMeetingInvitationRoutes,
    OwnersMeetingProtocolRoutes,
    PaymentRoutes,
    ProfitAndLossRoutes,
    PropertyRoutes,
    ResolutionRecordRoutes,
    SerialLetterRoutes,
    SpecialContributionRoutes,
    StandingOrderRoutes,
    // TemplateEditorRoutes,
    TransactionImportRoutes,
    WkaRoutes,
    OpsCostReportRoutes,
    MessagesRoutes,
    WatermarkRoutes,
    UserRoutes,
    DocumentPreviewRoutes,
    EInvoiceRoutes,
  ];

  return (
    <Router history={history}>
      <ContextProvider>
        <Route
          render={({ location }) => (
            <Route path="*">
              <Switch>
                <SentryRoute location={location} exact path="/login" component={Login} />
                <SentryRoute location={location} exact path="/register" component={Register} />
                <PrivateRoute path="/">
                  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                    <Layout>
                      <RenderRoutes
                        location={location}
                        routes={allRoutes}
                      >
                        <SentryRoute
                          location={location}
                          exact
                          path="/"
                          render={() => <Redirect to="/dashboard" />}
                        />
                      </RenderRoutes>
                    </Layout>
                    <JsPlugins />
                  </Sentry.ErrorBoundary>
                </PrivateRoute>
              </Switch>
            </Route>
          )}
        />
      </ContextProvider>
    </Router>
  );
}
