import { PropertyImportStatus } from './interfaces';

export const translations = {
  notifications: {
    loadError: {
      en: 'Connection could not be loaded.',
      de: 'Verbindung wurde nicht gefunden.',
    },
    casavi: {
      tokenError: {
        en: 'Error getting casavi token.',
        de: 'casavi Token konnte nicht erstellt werden.',
      },
      propertyError: {
        en: 'Error communicating with casavi.',
        de: 'Kommunikationsfehler mit casavi.',
      },
    },
    facilioo: {
      credentialsError: {
        en: 'Error getting facilioo credentials.',
        de: 'facilioo Verbindung konnte nicht erstellt werden.',
      },
      propertyError: {
        en: 'Error communicating with facilioo.',
        de: 'Kommunikationsfehler mit facilioo.',
      },
    },
    etg24: {
      propertyError: {
        en: 'Error communicating with etg24.',
        de: 'Kommunikationsfehler mit etg24.',
      },
    },
    started: {
      title: {
        en: 'Import process has started.',
        de: 'Der Importvorgang wurde gestartet.',
      },
      description: {
        en: 'Do not close the window until the import process is finished. Each property can take 30 seconds to 2 minutes to import.',
        de: 'Schließen Sie das Fenster nicht, bis der Importvorgang abgeschlossen ist. Der Import jedes Objektes kann 30 Sekunden bis 2 Minuten dauern.',
      },
    },
    finished: {
      title: {
        en: 'Import process has finished.',
        de: 'Importvorgang ist abgeschlossen.',
      },
      imported: {
        en: 'properties were imported.',
        de: 'Objekte wurden importiert.',
      },
      failed: {
        en: 'properties failed to import.',
        de: 'Objekte konnten nicht importiert werden.',
      },
      existing: {
        en: 'properties were already existing.',
        de: 'Objekte existieren schon.',
      },
    },
    prompt: {
      en: 'Import in progress. Are you sure?',
      de: 'Import läuft. Sind Sie sicher?',
    },
  },
  header: {
    title: {
      en: 'Import from',
      de: 'Importieren aus',
    },
    dropMenu: {
      createBackup: {
        en: 'Create backup',
        de: 'Backup erstellen',
      },
      resetExternalIds: {
        en: 'Reset external IDs',
        de: 'Zurücksetzen externer IDs',
      },
      importAllServiceCompanies: {
        en: 'Import all service companies',
        de: 'Importieren Sie alle Dienstleistungsunternehmen',
      },
    },
    dropMenuModal: {
      createBackup: {
        title: {
          en: 'Create backup',
          de: 'Backup erstellen',
        },
        line: {
          en: 'You will create a backup of your data in facilioo so that you can retrieve your data if something goes wrong during the import.',
          de: 'Erstellen Sie eine Sicherungskopie Ihrer Daten in facilioo, so dass Sie Ihre Daten wiederherstellen können, falls beim Import etwas schief geht.',
        },
        ok: {
          en: 'Create backup',
          de: 'Backup erstellen',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        success: {
          en: 'Backup creation process is complete. The backup document has been uploaded to the DMS and is available for download at any time.',
          de: 'Der Backup-Erstellungsprozess ist abgeschlossen. Das Backup-Dokument wurde im DMS hochgeladen und kann jederzeit heruntergeladen werden.',
        },
        error: {
          en: 'Backup creation process has failed.',
          de: 'Der Backup-Erstellungsprozess ist fehlgeschlagen.',
        },
      },
      resetExternalIds: {
        title: {
          en: 'Reset external IDs',
          de: 'Zurücksetzen externer IDs',
        },
        line: {
          en: 'Resets the external IDs in facilioo, making your data ready for accurate mapping during the import process.',
          de: 'Setzt die externen IDs in Facilioo zurück, damit Ihre Daten für eine präzise Zuordnung während des Importvorgangs vorbereitet sind.',
        },
        ok: {
          en: 'Reset external IDs',
          de: 'Zurücksetzen externer IDs',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        success: {
          en: (successfulCount: string) => `The resetting of external IDs has finished: ${successfulCount}`,
          de: (successfulCount: string) => `Die Zurücksetzung der externen IDs wurde abgeschlossen: ${successfulCount}`,
        },
        error: {
          title: {
            en: 'Reset of external IDs has finished. However, some errors occurred during the reset process.',
            de: 'Die Zurücksetzung der externen IDs wurde abgeschlossen. Allerdings sind während des Zurücksetzungsprozesses einige Fehler aufgetreten.',
          },
          successfulImportLine: {
            en: (successfulCount: string) => `Number of successfully reset external IDs: ${successfulCount}`,
            de: (successfulCount: string) => `Anzahl der erfolgreich zurückgesetzten externen IDs: ${successfulCount}`,
          },
          failedImportLine: {
            en: (failedCount: string) => `Number of external IDs that failed to reset: ${failedCount}`,
            de: (failedCount: string) => `Anzahl der externen IDs, die nicht zurückgesetzt werden konnten: ${failedCount}`,
          },
        },
      },
      importAllServiceCompanies: {
        title: {
          en: 'Import all service companies',
          de: 'Importieren Sie alle Dienstleistungsunternehmen',
        },
        line: {
          en: 'This step will import all service companies from facilioo into the system. By proceeding, you will ensure that all relevant service company data is available for your operations.',
          de: 'Dieser Schritt importiert alle Dienstleistungsunternehmen aus facilioo in das System. Durch das Fortfahren stellen Sie sicher, dass alle relevanten Daten der Dienstleistungsunternehmen für Ihre Operationen verfügbar sind.',
        },
        ok: {
          en: 'Import all service companies',
          de: 'Importieren Sie alle Dienstleistungsunternehmen',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        success: {
          en: (successfulCount: string) => `Import of all service companies has finished. Imported ${successfulCount} contacts.`,
          de: (successfulCount: string) => `Der Import aller Serviceunternehmen ist abgeschlossen. ${successfulCount} Kontakte importiert.`,
        },
        error: {
          title: {
            en: 'Import of all service companies has finished. However, some errors occurred during the import process.',
            de: 'Der Import aller Serviceunternehmen ist abgeschlossen. Während des Importvorgangs sind jedoch einige Fehler aufgetreten.',
          },
          successfulImportLine: {
            en: (successfulCount: string) => `Number of successfully imported companies: ${successfulCount}`,
            de: (successfulCount: string) => `Anzahl der erfolgreich importierten Unternehmen: ${successfulCount}`,
          },
          failedImportLine: {
            en: (failedCount: string) => `Number of companies that failed to import: ${failedCount}`,
            de: (failedCount: string) => `Anzahl der Unternehmen, deren Import fehlgeschlagen ist: ${failedCount}`,
          },
          requestTimeout: {
            en: 'The request to import all service companies has timed out. The process may still be running in the background.',
            de: 'Die Anfrage zum Import aller Serviceunternehmen ist abgelaufen. Der Prozess läuft möglicherweise weiterhin im Hintergrund.',
          },
        },
      },
    },
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    import: {
      en: 'Import',
      de: 'Importieren',
    },
  },
  section: {
    title: {
      en: 'Property',
      de: 'Objekt',
    },
    description: {
      line1: {
        part1: {
          en: 'We found',
          de: 'Wir haben',
        },
        part2: {
          en: 'properties',
          de: 'Objekte gefunden',
        },
      },
      line2: {
        en: 'Select the ones you want to import.',
        de: 'Wählen Sie diejenigen aus, die Sie importieren möchten.',
      },
      total: {
        en: 'Total selected: ',
        de: 'Insgesamt ausgewählt: ',
      },
      button: {
        en: 'Assign all as',
        de: 'Alle zuweisen als',
      },
    },
  },
  table: {
    sevTooltip: {
      en: 'The imported SEVs will not be linked to WEG.\nIf you want to have connection between your Impower managed SEV and Impower managed WEG. Do not import the SEV, but create it instead from our property UI.\nIf you do not manage the WEG or do not want a connection between SEV and WEG, then you should import it as SEV.',
      de: 'Die importierte SEV wird mit keiner WEG verbunden. Wenn Sie eine Verbindung zwischen Ihrer in Impower verwalteten SEV und Ihrer in Impower verwalteten WEG herstellen möchten, dann erstellen Sie bitte eine.\nSEV mit WEG-Verbindung über unsere Objektansicht.\nWenn Sie die WEG für diese SEV nicht verwalten oder keine Verbindung zwischen der SEV und der WEG benötigen, dann können Sie die SEV importieren.',
    },
    columns: {
      number: {
        en: 'Number',
        de: 'Nummer',
      },
      name: {
        en: 'Name',
        de: 'Name',
      },
      city: {
        en: 'City',
        de: 'Stadt',
      },
      type: {
        header: {
          en: 'Property type',
          de: 'Objekttyp',
        },
      },
      status: {
        header: {
          en: 'Status',
          de: 'Status',
        },
        contact: {
          en: 'Contact support',
          de: 'Support kontaktieren',
        },
        states: {
          [PropertyImportStatus.FAILED]: {
            en: 'Failed to import',
            de: 'Import fehlgeschlagen',
          },
          [PropertyImportStatus.IMPORTED]: {
            en: 'Imported',
            de: 'Importiert',
          },
          [PropertyImportStatus.EXISTING]: {
            en: 'Already existing',
            de: 'Existiert schon',
          },
          [PropertyImportStatus.IDLE]: {
            en: 'Ready',
            de: 'Bereit',
          },
          [PropertyImportStatus.IMPORTING]: {
            en: 'Importing...',
            de: 'Wird importiert...',
          },
          [PropertyImportStatus.NO_IMPORT]: {
            en: 'Won\'t be imported',
            de: 'Wird nicht importiert',
          },
          [PropertyImportStatus.PENDING]: {
            en: 'Pending',
            de: 'Ausstehend',
          },
        },
      },
    },
  },
};
