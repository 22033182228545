import { PropsWithChildren, createContext, useState } from 'react';
import { StoreApi, createStore } from 'zustand';
import { HeatingCenterGeneralDataStore, TempCO2DistributionTypeEnum, TempHeatingCenterAreaEnum } from './interfacesHeatingCenterGeneralData';
import {
  onChangeReportingSettings,
  onChangeGeneralInformation,
  onInitializeFormDataFromHeatingCenterDto,
  onChangeDirty,
  onChangeValidationStates,
} from './actionsHeatingCenterGeneralData';


const createHeatingCenterGeneralDataStore = () => (
  createStore<HeatingCenterGeneralDataStore>(set => ({
    isDirty: false,
    generalInformation: {
      name: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      location: '',
      country: 'DE',
    },
    reportingSettings: {
      vatRelevance: undefined,
      totalArea: undefined,
      areaType: TempHeatingCenterAreaEnum.NO_OVERRIDE,
      co2DistributionPercentage: undefined,
      co2DistributionType: TempCO2DistributionTypeEnum.NO_OVERRIDE,
      markNr1: undefined,
      markNr2: undefined,
      nonResidentialBuilding: undefined,
      heatConnectionAfter2023: undefined,
      heatingDefaultDistributionId: undefined,
      warmWaterDefaultDistributionId: undefined,
    },
    validationStates: {},
    actions: {
      onChangeGeneralInformation: (...args) => onChangeGeneralInformation(set, ...args),
      onChangeReportingSettings: (...args) => onChangeReportingSettings(set, ...args),
      onChangeDirty: (...args) => onChangeDirty(set, ...args),
      onChangeValidationStates: (...args) => onChangeValidationStates(set, ...args),
      onInitializeFormDataFromHeatingCenterDto: (...args) => onInitializeFormDataFromHeatingCenterDto(set, ...args),
    },
  }))
);


export const HeatingCenterGeneralDataStoreContext = createContext<StoreApi<HeatingCenterGeneralDataStore> | null>(null);


export const HeatingCenterGeneralDataStoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const [store] = useState(createHeatingCenterGeneralDataStore);

  return (
    <HeatingCenterGeneralDataStoreContext.Provider value={store}>
      {children}
    </HeatingCenterGeneralDataStoreContext.Provider>
  );
};
