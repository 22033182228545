import { StoreApi } from 'zustand';
import { produce } from 'immer';
import { TableRowSelection } from 'antd/lib/table/interface';
import { HeatingCenterUnitDto } from 'api/public';
import { HeatingCenterDataExchangeStore } from './interfacesHeatingCenterDataExchange';
import { DataExchangeTableDataSourceItem } from '../../components/HeatingCenterDataExchangeSection/components/HeatingCenterDataExchangeTableWithSearch/services/useHeatingCenterDataExchangeColumns';
import { GROUPING_ROW_FAKE_UNIT_ID } from '../../components/HeatingCenterDataExchangeSection/components/HeatingCenterDataExchangeTableWithSearch/services/useHeatingCenterDataExchangeTable';

type Set = StoreApi<HeatingCenterDataExchangeStore>['setState'];

type ArgsOfSelectOne = Parameters<TableRowSelection<DataExchangeTableDataSourceItem>['onSelect']>;
type ArgsOfSelectAll = Parameters<TableRowSelection<DataExchangeTableDataSourceItem>['onSelectAll']>;

/* Selection logic */
export const onSelectOne = (set: Set, record: ArgsOfSelectOne[0], selected: ArgsOfSelectOne[1], ...other: any) => (
  set(state => (
    produce(state, (draft) => {
      draft.isDirty = true;

      if (selected) {
        draft.selectedUnitIds = [...state.selectedUnitIds, record.unitId];
      } else {
        draft.selectedUnitIds = state.selectedUnitIds.filter(key => key !== record.unitId);
      }
    })
  ))
);
export const onSelectAll = (set: Set, selected: ArgsOfSelectAll[0], selectedRows: ArgsOfSelectAll[1], changeRows: ArgsOfSelectAll[2]) => (
  set(state => (
    produce(state, (draft) => {
      draft.isDirty = true;

      if (selected) {
        draft.selectedUnitIds = [...state.selectedUnitIds, ...changeRows.map(row => row.unitId).filter(unitId => unitId !== GROUPING_ROW_FAKE_UNIT_ID)];
      } else {
        draft.selectedUnitIds = state.selectedUnitIds.filter(key => !changeRows.some(row => row.unitId === key));
      }
    })
  ))
);


/* Simple onChange's */
export const onChangeShowOnlySelectedUnits = (set: Set, showOnlySelectedUnits: boolean) => (
  set(state => (
    produce(state, (draft) => {
      draft.showOnlySelectedUnits = showOnlySelectedUnits;
    })
  ))
);

export const onChangeDirty = (set: Set, isDirty: boolean) => (
  set(state => (
    produce(state, (draft) => {
      draft.isDirty = isDirty;
    })
  ))
);


export const onInitializeDataExchangeTableFromHeatingCenterUnits = (set: Set, heatingCenterUnits: HeatingCenterUnitDto[]) => (
  set(state => (
    produce(state, (draft) => {
      draft.selectedUnitIds = heatingCenterUnits.filter(hcu => hcu.checked).map(hcu => hcu.unitId);
    })
  ))
);
