import { useContext } from 'react';
import { useStore } from 'zustand';
import { HeatingCenterDataExchangeStoreContext } from './storeHeatingCenterDataExchange';
import { HeatingCenterDataExchangeStore } from './interfacesHeatingCenterDataExchange';

// ! do not export !
const useHeatingCenterDataExchangeStore = <T, >(selector: (state: HeatingCenterDataExchangeStore) => T): T => {
  const store = useContext(HeatingCenterDataExchangeStoreContext);

  if (!store) {
    throw new Error('Missing HeatingCenterDataExchangeStoreProvider.');
  }

  return useStore(store, selector);
};

/* Dirty, Validation */
export const useHeatingCenterDataExchangeDirty = () => useHeatingCenterDataExchangeStore(state => state.isDirty);

/* Selection */
export const useHeatingCenterSelectedUnitIds = () => useHeatingCenterDataExchangeStore(state => state.selectedUnitIds);
export const useHeatingCenterShowOnlySelectedUnits = () => useHeatingCenterDataExchangeStore(state => state.showOnlySelectedUnits);


/* Actions */
export const useHeatingCenterDataExchangeActions = () => useHeatingCenterDataExchangeStore(state => state.actions);
