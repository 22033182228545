import { UnitContractProjectionDtoUnitTypeEnum } from 'api/accounting';
import { HeatingCenterDtoStateEnum } from 'api/public';
import { articleUrls } from 'storybook-components/feedback/AlertArticleLink/ArticleUrls';

/* eslint-disable react/jsx-one-expression-per-line */
export const translationsHeatingCenterEditor = {
  pageTitle: {
    en: 'Heating center',
    de: 'Heizzentrale',
  },
  initializationNotifications: {
    loadDistributionSetsErrorNotification: {
      en: 'Failed to load distribution sets.',
      de: 'Verteilerschlüssel konnten nicht geladen werden.',
    },
    loadHeatingCenterErrorNotification: {
      en: 'Failed to load heating center.',
      de: 'Heizzentralen konnten nicht geladen werden.',
    },
    loadingHeatingCenterUnitsErrorNotification: {
      en: 'Failed to load heating center unit data.',
      de: 'Heizzentralen-Einheiten konnten nicht geladen werden.',
    },
  },
  header: {
    close: {
      en: 'Close',
      de: 'Schließen',
    },
    save: {
      en: 'Save',
      de: 'Speichern',
    },
    saveNotification: {
      success: {
        en: 'Heating center saved successfully.',
        de: 'Heizzentrale erfolgreich gespeichert.',
      },
      error: {
        en: 'Failed to save heating center.',
        de: 'Heizzentrale konnte nicht gespeichert werden.',
      },
    },
    status: {
      [HeatingCenterDtoStateEnum.DRAFT]: {
        en: 'Draft',
        de: ' Entwurf',
      },
      [HeatingCenterDtoStateEnum.READY]: {
        en: 'Mapped',
        de: 'Verknüpft',
      },
      [HeatingCenterDtoStateEnum.ERROR]: {
        en: 'Mapping Error',
        de: 'Verknüpfungsfehler',
      },
    },
  },
  generalDataSection: {
    sectionTitle: {
      en: 'General data',
      de: 'Allgemein',
    },
    howToButtonText: {
      en: 'How to?',
      de: 'Wie geht das?',
    },
    generalInformation: {
      subSectionTitle: {
        en: 'Heating center',
        de: 'Heizzentrale',
      },
      name: {
        en: 'Name',
        de: 'Name',
      },
      street: {
        en: 'Street',
        de: 'Straße',
      },
      number: {
        en: 'House number',
        de: 'Hausnummer',
      },
      postalCode: {
        en: 'Postal code',
        de: 'Postleitzahl',
      },
      location: {
        en: 'Location',
        de: 'Ort',
      },
      country: {
        en: 'Country',
        de: 'Land',
      },
      vatRelevance: {
        en: 'VAT relevance',
        de: 'Umsatzsteuerpflicht',
      },
      vatRelevanceOptions: {
        notRelevant: {
          en: 'Not VAT relevant',
          de: 'Nicht umsatzsteuerpflichtig',
        },
        relevant: {
          en: 'VAT relevance defined on the contract',
          de: 'Umsatzsteuerpflicht auf Vertrag festgelegt',
        },
      },
      validation: {
        NOT_UNIQUE: {
          en: 'Field must be unique',
          de: 'Feld muss eindeutig sein',
        },
        NOT_EMPTY: {
          en: 'Field is required',
          de: 'Feld ist erforderlich',
        },
        INVALID_NUMBER: {
          en: 'Larger than gross amount',
          de: 'Größer als Bruttobetrag',
        },
      },
    },
    reportingSettings: {
      subSectionTitle: {
        en: 'Settlement report settings',
        de: 'Einstellungen für die Abrechnung',
      },
      co2Subsection: {
        title: {
          en: 'CO2 settings',
          de: 'CO2 Einstellungen',
        },
        tooltip: {
          en: (<>Only relevant if HCD uses <a target="_blank" href="https://bved.info/datenaustausch/spezifikationen/" rel="noreferrer">BVED(ARGE)</a> version 3.10.</>),
          de: (<>Nur relevant wenn Messdienstleister den <a target="_blank" href="https://bved.info/datenaustausch/spezifikationen/" rel="noreferrer">BVED(ARGE)</a> Standard 3.10 nutzt.</>),
        },
      },
      area: {
        label: {
          en: 'Heating distribution total',
          de: 'Heizungsanteile Gesamt',
        },
        tooltip: {
          en: 'If differs from total of heating default distribution.',
          de: 'Bei Bedarf können Sie hier einen nutzerspezifischen Wert für die Heizungsanteile Gesamt eingeben wenn die Summe der Grundanteile von den Heizungsanteilen Gesamt abweicht. Die Gesamtanzahl können Sie dann in dem Feld “Gesamtwert” manuell eintragen. ',
        },
        noOverrideOptionLabel: {
          en: 'Sum of shares',
          de: 'Summe Grundanteile',
        },
        overrideOptionLabel: {
          en: 'User defined',
          de: 'Nutzerspezifisch',
        },
      },
      totalValue: {
        en: 'Total value',
        de: 'Gesamtwert',
      },
      distributionOfHeating: {
        en: 'Default distribution of heating',
        de: 'Standardverteilung Heizung',
      },
      distributionOfWarmWater: {
        en: 'Default distribution of warm water',
        de: 'Standardverteilung Warmwasser',
      },
      distributionOfCO2: {
        label: {
          en: (<>Distribution of CO<sub>2</sub>&nbsp;share owner</>),
          de: (<>Verteilung CO<sub>2</sub>&nbsp;Anteil Vermieter</>),
        },
        tooltip: {
          en: <>Indication of the landlord&apos;s share of the CO<sub>2</sub>&nbsp;costs depending on the carbon dioxide emissions of the building.</>,
          de: <>Angabe der Kostenbeteiligung der Vermieter an den CO<sub>2</sub>-Kosten abhängig vom Kohlendioxidausstoß des Gebäudes.</>,
        },
        noOverrideOptionLabel: {
          en: 'According to legislation',
          de: 'Laut gesetzliche Vorgaben',
        },
        noOverrideOptionHint: {
          en: (
            <span>
              The provider will calculate the correct values according to
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/anlage.html" rel="noopener noreferrer" target="_blank"> the legislation in place.</a>
            </span>
          ),
          de: (
            <span>
              Der Dienstleister wird die CO<sub>2</sub> Werte
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/anlage.html" rel="noopener noreferrer" target="_blank"> laut Gesetz verteilen.</a>
            </span>
          ),
        },
        overrideOptionLabel: {
          en: 'Custom Landlord Percent',
          de: 'Individueller Vermieteranteil',
        },
        overrideOptionHint: {
          en: 'Overrides the automatically calculated value at Provider.',
          de: 'Angegeben Wert überschreibt den vom Dienstleister automatisch errechneten Wert.',
        },
      },
      customValue: {
        label: {
          en: 'Custom value',
          de: 'Benutzerdefinierter Wert',
        },
        tooltip: {
          en: "Legally required landlord's share for non-residential building is min. 50%, a differing value may only be defined if the value is greater than the one calculated by law.",
          de: 'Gesetzlich vorgeschriebener Vermieteranteil bei Nichtwohngebäude ist min. 50%. Eine abweichende Angabe darf nur erfolgen, wenn der Vermieteranteil höher als der gesetzlich errechnete ausfällt.',
        },
      },
      markNr1: {
        label: {
          en: 'Mark Nº1 §9',
          de: 'Kennzeichen 1 §9',
        },
        tooltip: {
          en: 'Public-law requirements prevent a significant improvement in the energy efficiency of the building.',
          de: 'Öffentlich-rechtliche Vorgaben, z.B. Denkmalschutz, stehen einer wesentlichen energetischen Verbesserung des Gebäudes entgegen.',
        },
        yesOptionLabel: {
          en: 'Yes',
          de: 'Ja',
        },
        yesOptionHint: {
          en: 'Public-law requirements prevent a significant improvement in the energy efficiency of the building.',
          de: 'Es gibt Vorgaben für das Gebäude - wie Denkmalschutz - die eine energetische Verbesserung derzeit verhindern.',
        },
        noOptionLabel: {
          en: 'No',
          de: 'Nein',
        },
        noOptionHint: {
          en: 'There are no requirements for the building that prevent an energy improvement.',
          de: 'Es gibt keine Vorgaben für das Gebäude, die eine energetische Verbesserung verhindern.',
        },
      },
      markNr2: {
        label: {
          en: 'Mark Nº2 §9',
          de: 'Kennzeichen 2 §9',
        },
        tooltip: {
          en: 'Public-law requirements prevent a significant improvement in the heating and water supply of the building. For example, heat supply with mandatory connection and usage.',
          de: 'Öffentlich-rechtliche Vorgaben stehen einer wesentlichen Verbesserung der Wärme-und Wasserversorgung des Gebäudes entgegen. Bspw. Wärmelieferung mit Anschluss- und Benutzungszwang.',
        },
        yesOptionLabel: {
          en: 'Yes',
          de: 'Ja',
        },
        yesOptionHint: {
          en: 'Public-law requirements prevent a significant improvement in the heating and water supply of the building.',
          de: 'Es gibt Vorgaben für das Gebäude - wie Wärmelieferung mit Anschluss- und Benutzungszwang - die eine Verbesserung  der Wärme- und Wasserversorgung verhindern.',
        },
        noOptionLabel: {
          en: 'No',
          de: 'Nein',
        },
        noOptionHint: {
          en: 'There are no requirements for the building that prevent an improvement in the heating and water supply.',
          de: 'Es gibt keine Vorgaben für das Gebäude, die eine Verbesserung  der Wärme- und Wasserversorgung verhindern. ',
        },
      },
      nonResidentialBuilding: {
        label: {
          en: 'Non-residential building',
          de: 'Nichtwohngebäude',
        },
        tooltip: {
          en: (
            <span>
              Nonresidential building has more than 50% commercial area, according to
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/__8.html" rel="noopener noreferrer" target="_blank"> § 8 CO2KostAufG</a>.
            </span>
          ),
          de: (
            <span>
              Nichtwohngebäude, mehr als 50% der Fläche ist gewerblich, gemäß
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/__8.html" rel="noopener noreferrer" target="_blank"> § 8 CO2KostAufG</a>.
            </span>
          ),
        },
        yesOptionLabel: {
          en: 'Yes',
          de: 'Ja',
        },
        noOptionLabel: {
          en: 'No',
          de: 'Nein',
        },
        yesOptionHint: {
          en: 'more than 50% commercial',
          de: 'mehr als 50% gewerblich',
        },
        noOptionHint: {
          en: 'less than 50% commercial',
          de: 'weniger als 50% gewerblich',
        },
      },
      heatConnectionAfter2023: {
        label: {
          en: 'Heating system after 01.01.2023',
          de: 'Heizungsanschluss nach 01.01.2023',
        },
        tooltip: {
          en: (
            <span>
              Heat connection of the property on or after 01.01.2023
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/__2.html" rel="noopener noreferrer" target="_blank"> §2 para. 3, CO2KostAufG </a>
              Heat supplies from heating systems that are subject to European emissions trading.
            </span>
          ),
          de: (
            <span>
              Wärmeanschluss des Objektes am oder nach dem 01.01.2023.
              <a href="https://www.gesetze-im-internet.de/co2kostaufg/__2.html" rel="noopener noreferrer" target="_blank"> §2 Absatz 3, CO2KostAufG </a>
              Wärmelieferungen aus Wärmeanlagen, die dem europäischen Emissionshandel unterliegen.
            </span>
          ),
        },
        yesOptionLabel: {
          en: 'Yes',
          de: 'Ja',
        },
        noOptionLabel: {
          en: 'No',
          de: 'Nein',
        },
        yesOptionHint: {
          en: 'The building has received a heat connection after 01.01.2023. The law on the distribution of carbon dioxide costs (CO2KostAufG) between tenant and landlord does not apply here.',
          de: 'Das Gebäude hat nach dem 01.01.2023 einen Wärmeanschluss erhalten. Das Gesetz zur Aufteilung der Kohlendioxidkosten (CO2KostAufG) zwischen Mieter und Vermieter gilt hier nicht.',
        },
        noOptionHint: {
          en: 'A heat connection after 01.01.2023 has not taken place. The law on the distribution of carbon dioxide costs (CO2KostAufG) between tenant and landlord applies.',
          de: 'Ein Wärmeanschluss nach dem 01.01.2023 hat nicht stattgefunden. Das Gesetz zur Aufteilung der Kohlendioxidkosten (CO2KostAufG) zwischen Mieter und Vermieter gilt.',
        },
      },
      heatingDistribution: {
        label: {
          en: 'Heating default distribution',
          de: 'Heizung Grundanteile',
        },
        tooltip: {
          en: 'Generally, the allocation of heating costs is divided into consumption-based costs and basic costs. Here, you can define the basic shares for heating (e.g., heating area). The settings for how costs are divided between consumption-based and basic shares (e.g., 70% consumption-dependent, 30% consumption-independent) must be determined with your metering service provider and cannot be configured here.',
          de: 'Generell erfolgt die Aufteilung der Heizkosten nach Verbrauchskosten und Grundkosten. Hier können Sie die Grundanteile Heizung (bspw. Heizfläche) festlegen. Die Einstellung wie Kosten zwischen Verbrauch und Grundanteile aufgeteilt werden (bspw. 70% verbrauchsabhängig, 30% verbrauchsunabhängig) müssen Sie mit Ihrem Messdienstleister festlegen und können hier nicht festgelegt werden. ',
        },
        placeholder: {
          en: 'Not selected',
          de: 'Nicht ausgewählt',
        },
      },
      warmWaterDistribution: {
        label: {
          en: 'Warm water default distribution',
          de: 'Warmwasser Grundanteile',
        },
        tooltip: {
          en: 'Generally, the allocation of hot water costs is divided into consumption-based costs and basic costs. Here, you can define the basic shares for hot water (e.g., heating area). The settings for how costs are divided between consumption-based and basic shares (e.g., 70% consumption-dependent, 30% consumption-independent) must be determined with your metering service provider and cannot be configured here.',
          de: 'Generell erfolgt die Aufteilung der Warmwasserkosten nach Verbrauchskosten und Grundkosten. Hier können Sie die Grundanteile Warmwasser (bspw. Heizfläche) festlegen. Die Einstellung wie Kosten zwischen Verbrauch und Grundanteile aufgeteilt werden (bspw. 70% verbrauchsabhängig, 30% verbrauchsunabhängig) müssen Sie mit Ihrem Messdienstleister festlegen und können hier nicht festgelegt werden.',
        },
        placeholder: {
          en: 'Not selected',
          de: 'Nicht ausgewählt',
        },
      },
      loadingLabel: {
        en: 'Loading...',
        de: 'Laden...',
      },
      createNewDistribution: {
        en: 'Create new distribution',
        de: 'Neuen Verteilerschlüssel erstellen',
      },
      distributionPrefix: {
        warning: {
          en: 'Please open to enter values',
          de: 'Bitte öffnen um Werte einzutragen',
        },
        info: {
          en: 'Please open to verify values',
          de: 'Bitte öffnen um Werte zu verifizieren',
        },
      },
    },
    saveAndContinue: {
      en: 'Save and continue',
      de: 'Speichern und Weiter',
    },
  },
  dataExchangeSection: {
    downloadRulModal: {
      title: {
        en: 'Download RUL',
        de: 'Einheitenliste (RUL) herunterladen',
      },
      startDate: {
        en: 'Start date',
        de: 'Startdatum',
      },
      endDate: {
        en: 'End date',
        de: 'Enddatum',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      download: {
        en: 'Download',
        de: 'Herunterladen',
      },
    },
    sectionTitle: {
      en: 'Data exchange',
      de: 'Datenübertragung',
    },
    inputs: {
      bvedVersion: {
        label: {
          en: 'BVED version',
          de: 'BVED-Version',
        },
        tooltip: {
          en: 'Version for data exchange.',
          de: 'Version für Datenaustausch.',
        },
      },
      customerNr: {
        label: {
          en: 'Customer-Nr.',
          de: 'Kundennummer',
        },
        tooltip: {
          en: 'Number of the customer under which they are registered with the billing company.',
          de: 'Kundennummer beim Abrechnungsunternehmen.',
        },
      },
      providerName: {
        label: {
          en: 'Provider name',
          de: 'Abrechnungsunternehmen',
        },
        tooltip: {
          en: 'Name of the billing company.',
          de: 'Name des Abrechnungsunternehmens.',
        },
      },
      propertyId: {
        en: 'Property-ID',
        de: 'Objekt-ID',
      },
      searchBar: {
        label: {
          en: 'Units of heating center',
          de: 'Einheiten der Heizzentrale auswählen',
        },
        checkboxLabel: {
          en: 'Show selected units',
          de: 'Ausgewählte Einheiten anzeigen',
        },
      },
      placeholder: {
        en: 'Will be pre-filled with ASet file',
        de: 'Wird mit ASet-Datei vorbefüllt',
      },
    },
    table: {
      columns: {
        unitNr: {
          en: 'Unit-nr',
          de: 'Einheits-Nr',
        },
        owner: {
          en: 'Owner',
          de: 'Eigentümer',
        },
        tenant: {
          en: 'Tenant',
          de: 'Mieter',
        },
        category: {
          en: 'Category',
          de: 'Kategorie',
        },
        building: {
          en: 'Building',
          de: 'Gebäude',
        },
        mscId: {
          label: {
            en: 'MSC-id',
            de: 'MSC-ID',
          },
          error: {
            en: 'Missing ID',
            de: 'Fehlende ID',
          },
        },
        mappingState: {
          en: 'Mapping state',
          de: 'Mapping Status',
        },
        unitTypes: {
          [UnitContractProjectionDtoUnitTypeEnum.APARTMENT]: {
            en: 'Apartment',
            de: 'Wohnung',
          },
          [UnitContractProjectionDtoUnitTypeEnum.COMMERCIAL]: {
            en: 'Commercial',
            de: 'Gewerbeeinheit',
          },
          [UnitContractProjectionDtoUnitTypeEnum.PARKING]: {
            en: 'Parking',
            de: 'Parkmöglichkeit',
          },
          [UnitContractProjectionDtoUnitTypeEnum.OTHER]: {
            en: 'Other',
            de: 'Sonstiges',
          },
        },
        mappingStates: {
          mapped: {
            en: 'Mapped',
            de: 'Verknüpft',
          },
          notMapped: {
            en: 'Not mapped',
            de: 'Nicht verknüpft',
          },
        },
      },
      otherUnits: {
        en: 'Other units',
        de: 'Sonstige Einheiten',
      },
      vacancy: {
        en: 'Vacancy',
        de: 'Leerstand',
      },
      summary: {
        selectedUnits: {
          en: 'Units selected: ',
          de: 'Einheiten ausgewählt: ',
        },
        noUnitsSelected: {
          en: 'Select units to download the RUL file',
          de: 'Wählen Sie Einheiten aus, um die RUL-Datei herunterzuladen',
        },
        unitsVisible: {
          en: 'match the search',
          de: 'entsprechen der Suche',
        },
      },
    },
    missingMappingsAlert: {
      message: {
        en: 'ASet upload issues',
        de: 'ASet-Upload-Probleme',
      },
      description: {
        en: (nrErrors: number) => (
          <>
            <ul>
              <li>The number of selected units does not match with the number of units in the uploaded ASet file.</li>
              <li>{nrErrors} entries were not recognised.</li>
            </ul>
            <p>
              Incorrectly mapped units can lead to inaccurate heating cost distribution reports.<br />
              <a href={articleUrls.onlineWka} rel="noopener noreferrer" target="_blank">Read our article</a> on how to resolve common issues.
            </p>
          </>
        ),
        de: (nrErrors: number) => (
          <>
            <ul>
              <li>Die Anzahl der ausgewählten Einheiten stimmt nicht mit der Anzahl der Einheiten in der hochgeladenen ASet-Datei überein.</li>
              <li>{nrErrors} Einträge wurden nicht erkannt.</li>
            </ul>
            <p>
              Falsch zugeordnete Einheiten können zu ungenauen Heizkostenverteilungsberichten führen.<br />
              <a href={articleUrls.onlineWka} rel="noopener noreferrer" target="_blank">Lesen Sie unseren Artikel</a> zur Behebung häufiger Probleme.
            </p>
          </>
        ),
      },
    },
    multipleHcAlert: {
      en: 'This heating center contains mappings from multiple heating centers.',
      de: 'Diese Heizzentrale enthält Zuordnungen aus mehreren Heizzentralen.',
    },
    rulErrorAlertTitle: {
      en: 'Review tenant contracts',
      de: 'Mietverträge überprüfen',
    },
    contractError: {
      en: 'Make sure that the following tenant contracts do not overlap with other contracts or that the specified date range is fully covered by valid contracts.',
      de: 'Achten Sie darauf, dass sich die folgenden Mietverträge nicht mit anderen Verträgen überschneiden oder dass der angegebene Zeitraum vollständig durch gültige Verträge abgedeckt ist.',
    },
    contract: {
      en: 'Contract',
      de: 'Vertrag',
    },
    steps: {
      step1: {
        title: {
          en: 'Step 1',
          de: 'Schritt 1',
        },
        description: {
          en: 'Select the units you want to connect to the heating center from the table, then download the unit data and send it to the provider.',
          de: 'Wählen Sie aus der Tabelle die Einheiten aus, die Sie mit der Heizzentrale verbinden möchten, laden Sie dann die Einheitendaten herunter und senden Sie diese an den Dienstleister.',
        },
      },
      step2: {
        title: {
          en: 'Step 2',
          de: 'Schritt 2',
        },
        description: {
          en: 'Upload the mapped unit data set that your provider has sent you and make sure that the selected units are mapped correctly in the table.',
          de: 'Laden Sie den zugeordneten Einheitendatensatz hoch, den Sie von Ihrem Dienstleister erhalten haben, und stellen Sie sicher, dass die ausgewählten Einheiten in der Tabelle korrekt zugeordnet sind.',
        },
      },
    },
    footerButtons: {
      downloadRUL: {
        en: 'Download RUL',
        de: 'Einheitenliste (RUL) herunterladen',
      },
      uploadASet: {
        en: 'Upload ASet',
        de: 'Nutzerdaten (A-Satz) hochladen',
      },
      lastDownloadDate: {
        en: 'Last download date',
        de: 'Zuletzt heruntergeladen',
      },
      lastUploadDate: {
        en: 'Last upload date',
        de: 'Zuletzt hochgeladen',
      },
      notifications: {
        downloadFailed: {
          en: 'Failed to download RUL.',
          de: 'RUL konnte nicht heruntergeladen werden.',
        },
        uploadFailed: {
          en: 'Failed to upload ASet.',
          de: 'ASet konnte nicht hochgeladen werden.',
        },
        uploadSuccess: {
          en: 'ASet uploaded successfully.',
          de: 'ASet erfolgreich hochgeladen.',
        },
      },
    },
  },
};
