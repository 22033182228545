import {
  useContext,
  useEffect,
} from 'react';

import {
  CasaviSyncDataDtoStateEnum,
  ContactLegacyControllerApi,
  ContactLegacyDto,
  ContractLegacyControllerApi,
  EPostControllerApi,
  MessageCreateDtoLetterDispatchTypeEnum,
  PropertyLegacyControllerApi,
  PropertyLegacyDto,
  UnitContractControllerApi,
  UnitContractProjectionDto,
  UnitContractProjectionDtoTypeEnum,
} from 'api/accounting';
import {
  Configuration as AppConfiguration,
  ConnectionLegacyDto,
  ConnectionsApi,
  GetConnectionsUsingGETStateEnum,
  GetSyncDataUsingGETResourceNameEnum,
  SyncDataApi,
  SyncDataDto,
  SyncDataDtoStateEnum,
} from 'api/app';
import {
  Configuration as DocumentConfiguration,
  DocumentLegacyControllerApi,
  DocumentProjectionDto,
  FindDocumentsFilteredUsingGETOrderEnum,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _, {
  groupBy,
  isEmpty,
  isNil,
  uniq,
} from 'lodash';
import { nanoid } from 'nanoid';
import { DocumentDatasource, RecipientContractContact } from 'pages/MessageSendingPage/interfaces';
import { useLocation } from 'react-router';
import { useQueryStringSplitter } from 'services/useQueryStringSplitter';

import { MAX_PAGE_SIZE } from 'lib/Utils';
import {
  MessageSendingInitializationStateType,
  MessageSendingProperty,
  PropertyConnection,
} from '../interfaces';
import { messageSendingTranslations } from '../translations';
import {
  useDocumentContext,
  useMessageSendingContext,
  useMessageSendingPropertyContext,
  useRecipientContext,
  useRecipientUpdaterContext,
  useSyncDataContext,
} from './MessageSendingContext';

const validateRecipientAddress = (recipient: ContactLegacyDto): boolean => {
  const germanPostalCodeRegex = /^[0-9]{5}$/;
  const address = recipient.addresses?.[0];

  if (_.isNil(address)) {
    return false;
  }

  const {
    country, city, street, number, postalCode,
  } = address;

  // Check if all address fields are complete
  const hasCompleteAddressFields = !_.isEmpty(_.trim(country))
    && !_.isEmpty(_.trim(city))
    && !_.isEmpty(_.trim(street))
    && !_.isEmpty(_.trim(number))
    && !_.isEmpty(_.trim(postalCode));

  // validate postal code for German addresses only
  if (country === 'DE') {
    return hasCompleteAddressFields && germanPostalCodeRegex.test(postalCode);
  }

  return hasCompleteAddressFields;
};

const getValidatedContract = (contract: UnitContractProjectionDto, contracts: UnitContractProjectionDto[]) => {
  const { unitContractId, contractGroupId } = contract;

  if (isNil(contractGroupId) || (!isNil(contractGroupId) && unitContractId === contractGroupId)) {
    return contracts?.find(c => c.unitContractId === contract.unitContractId);
  }

  // for SEV contracts, validate the linked WEG contract
  if (!isNil(contractGroupId) && unitContractId !== contractGroupId) {
    return contracts?.find(c => c.unitContractId === contract.contractGroupId);
  }
}

const getPropertyForLink = (contract: UnitContractProjectionDto, properties: PropertyLegacyDto[], contracts: UnitContractProjectionDto[]) => {
  const { unitContractId, contractGroupId } = contract;

  if (isNil(contractGroupId) || (!isNil(contractGroupId) && unitContractId === contractGroupId)) {
    return properties?.find(p => p.id === contract.propertyId);
  }

  // for SEV contracts take the propertyId of the WEG contract it is linked to
  if (!isNil(contractGroupId) && unitContractId !== contractGroupId) {
    const linkedPropertyId = contracts.find(c => c.unitContractId === contractGroupId).propertyId;
    return properties?.find(p => p.id === linkedPropertyId);
  }
}

const validateContractSyncData = (contract: UnitContractProjectionDto, contractsSyncData: SyncDataDto[]) => {
  const { unitContractId, contractGroupId } = contract;
  let contractSyncData;

  // for regular contracts and WEG tenant contracts linked to an SEV check own sync data
  if (isNil(contractGroupId) ||
    (!isNil(contractGroupId) && unitContractId === contractGroupId)) {
    contractSyncData = contractsSyncData.find(sd => sd.entityId === unitContractId);
  }

  // for SEV contracts, check sync data of linked WEG tenant contract
  if (!isNil(contractGroupId) && unitContractId !== contractGroupId) {
    contractSyncData = contractsSyncData.find(sd => sd.entityId === contractGroupId);
  }


  // not valid if no contractSyncData is found
  if (_.isEmpty(contractSyncData)) {
    return false;
  }

  // Check if contract is synced based on state and externalEntityId
  return contractSyncData.state !== SyncDataDtoStateEnum.FAILED && !_.isEmpty(contractSyncData.externalEntityId);
};

const validateContactSyncData = (contractContact: RecipientContractContact, contactsSyncData: SyncDataDto[]): boolean => {
  const contactSyncData = contactsSyncData.find(cs => cs.entityId == contractContact.contactId);

  // not valid if no contactSyncData is found
  if (_.isEmpty(contactSyncData)) {
    return false;
  }

  // Check if contact is synced based on state and externalEntityId
  return contactSyncData.state !== SyncDataDtoStateEnum.FAILED && !_.isEmpty(contactSyncData.externalEntityId);
};

const validatePropertySync = (contract: UnitContractProjectionDto, contracts: UnitContractProjectionDto[], properties: PropertyLegacyDto[], connections: PropertyConnection[]): boolean => {
  const { propertyId, unitContractId, contractGroupId } = contract;
  const property = properties.find(prp => prp.id === propertyId);

  // represents which connections are enabled on the domain the property is on
  const connectionForProperty = connections.find(conn => conn.propertyId === property.id);

  // only validate sync data of property for casavi connection -> for etg24 and facilioo contract/contact sync data is validated
  if (!connectionForProperty.activePortals.includes('casavi')) {
    return true;
  }

  let propertyToValidate;
  // for regular contracts and WEG tenant contracts linked to an SEV contract validate own property
  if (isNil(contractGroupId) || (!isNil(contractGroupId) && unitContractId === contractGroupId)) {
      propertyToValidate = properties.find(p => p.id === propertyId);
  }

  // for SEV contracts, validate the property of the WEG tenant contract
  if (!isNil(contractGroupId) && unitContractId !== contractGroupId) {
    const linkedContract = contracts.find(c => c.unitContractId === contractGroupId);
    propertyToValidate = properties.find(p => p.id === linkedContract.propertyId);
    console.log(propertyToValidate);
  }

  return propertyToValidate.casaviActive && !_.isNil(propertyToValidate.casaviId) && propertyToValidate.casaviSyncData.state === CasaviSyncDataDtoStateEnum.SUCCESS;
};

const PORTAL_CONNECTION_NAMES = ['facilioo', 'etg24', 'casavi'];
const ERROR_LOADING_CONNECTIONS = 'Failed to load connections';

export const useInitializeMessageSendingPage = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation<MessageSendingInitializationStateType>();

  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as DocumentConfiguration);
  const connectionApi = new ConnectionsApi(apiConfiguration('app') as unknown as AppConfiguration);
  const epostControllerApi = new EPostControllerApi(apiConfiguration('accounting'));
  const contactControllerApi = new ContactLegacyControllerApi(apiConfiguration('accounting'));

  const { appApiConfiguration } = useContext(AuthContext);
  const syncDataApi = new SyncDataApi(appApiConfiguration('app'));

  const messageSendingContext = useMessageSendingContext('useInitializeMessageSendingPage');
  const recipientUpdaterContext = useRecipientUpdaterContext('useInitializeMessageSendingPage');
  const propertyContext = useMessageSendingPropertyContext('useInitializeMessageSendingPage');
  const documentContext = useDocumentContext('useInitializeMessageSendingPage');
  const recipientContext = useRecipientContext('useInitializeMessageSendingPage');
  const syncDataContext = useSyncDataContext('useInitializeMessageSendingPage');

  const {
    properties, setProperties, setPropertyConnections, propertyConnections,
  } = propertyContext;
  const {
    setRecipientDistributions, setDocumentRecipients, setContracts, setContactsLoading,
  } = recipientUpdaterContext;
  const { setDocumentDatasource } = documentContext;
  const { documentRecipients, contracts } = recipientContext;
  const { setDirty, setSource, setWarnings } = messageSendingContext;
  const {
    contractsSyncData, setContractsSyncData, contactsSyncData, setContactsSyncData,
  } = syncDataContext;

  const { splitRequestParam } = useQueryStringSplitter();

  useEffect(() => {
    if (!_.isEmpty(location.state?.properties)) {
      setDocumentRecipients(location.state?.properties?.flatMap(prp => prp.documents.flatMap(dr => dr.recipients
        ?.map(r => ({
          propertyId: prp.propertyId,
          documentId: dr.documentId,
          contractId: r?.contractId,
          contactId: r?.contactId,
        })))));

      const propertyParams = location.state?.properties;
      const contractIds = uniq(propertyParams
        .flatMap(prp => prp.documents
          .flatMap(dr => dr.recipients?.map(r => r?.contractId)))
        .filter(id => id !== undefined));
      const contactIds = uniq(propertyParams
        .flatMap(prp => prp.documents
          .flatMap(dr => dr.recipients?.map(r => r?.contactId)))
        .filter(id => id !== undefined));
      const propertyIds = uniq(propertyParams?.map(prp => prp.propertyId));

      loadProperties(propertyIds)
        .then(result => loadConnections(result))
        .then(result => loadContracts(propertyIds, contractIds, result?.properties, result?.connections))
        .then(result => loadLinkedPropetiesAndContracts(result?.properties, result?.contracts, result?.connections))
        .then(result => loadDocuments(propertyParams, result?.properties, result?.contracts, result?.connections))
        .then(result => loadContractAndContactSyncData(result?.contracts, contactIds, result?.connections))
        .catch((err) => {
          if (err?.message && err.message === ERROR_LOADING_CONNECTIONS) {
            showNotification({
              key: 'loadConnectionsError',
              message: tl(messageSendingTranslations.notifications.loadConnectionError),
              type: 'error',
            });
            console.error('Resource loading stopped because connections necessary for validations could not be loaded.');
          }
          console.error('Properties could not be loaded, error is handled in loadProperties.', err);
        });
    }
    // we need to store the source info in state in order to not lose it after navigating to document preview
    if (![undefined, null].includes(location.state)) {
      setSource({ sourceType: location.state?.sourceType, sourceId: location.state?.sourceId });
    }
  }, [location.state]);

  useEffect(() => {
    const uniqContactIds = _.uniq(documentRecipients?.map(dr => dr.contactId));
    if (_.isEmpty(uniqContactIds) || _.isEmpty(propertyConnections)
        || !contracts.loaded || !properties.loaded || !contractsSyncData.loaded || !contactsSyncData.loaded) return;

    loadContactsByIds(uniqContactIds)
      .then(initializeRecipientDistributions);
  }, [documentRecipients, propertyConnections, contracts, properties, contractsSyncData, contactsSyncData]);

  const loadProperties = (propertyIds: number[]) => {
    setProperties(prev => prev.startLoading());
    const promises = _.uniq(propertyIds).map(propertyId => propertyControllerApi.getPropertyByIdUsingGET({ propertyId }));
    return Promise.all(promises)
      .then((propertyResults) => {
        const tempProperties: PropertyLegacyDto[] = [];
        propertyResults.forEach((res) => {
          tempProperties.push(res);
        });
        setProperties(prev => prev.load(tempProperties));
        return Promise.resolve(tempProperties);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.loadPropertyError),
        });
        return undefined;
      });
  };

  const getEpostTokenForManagementCompany = (managementCompanyId: number) => epostControllerApi.getEpostTokenUsingGET({ managementCompanyId })
    .then(token => Promise.resolve({ managementCompanyId, token }))
    .catch(err => Promise.reject({ managementCompanyId, error: err }));

  const loadConnections = (prps: PropertyLegacyDto[]) => {
    if (prps === undefined) return Promise.reject();
    const connectionPromise = connectionApi.getConnectionsUsingGET({ state: GetConnectionsUsingGETStateEnum.READY });
    const managementCompanyIds = _.uniq(prps?.map(p => p.managementCompany?.id));
    const epostTokenPromises = managementCompanyIds.map(mId => getEpostTokenForManagementCompany(mId));
    return Promise.allSettled([connectionPromise, ...epostTokenPromises])
      .then(([connectionResp, ...epostTokenResponses]) => {
        let tempPropertyConntections: PropertyConnection[] = prps?.map(p => ({
          propertyId: p.id, managementCompanyId: p.managementCompany?.id, epostEnabled: false,
        }));
        let domainPortalConnections: ConnectionLegacyDto[] = [];

        // handle connections
        if (connectionResp.status === 'rejected') {
          // if connections could not be loaded
          console.error(connectionResp.reason);
          return Promise.reject({ message: ERROR_LOADING_CONNECTIONS });
        }
        // if connections successfully loaded, set active portal connection info for all properties
        domainPortalConnections = connectionResp.value.content?.filter(c => PORTAL_CONNECTION_NAMES.includes(c.appName));
        const portalConnections = connectionResp.value.content?.filter(c => PORTAL_CONNECTION_NAMES.includes(c.appName));
        tempPropertyConntections = tempPropertyConntections.map((prpCon) => {
          const activePortals = portalConnections.map(c => c.appName);
          return { ...prpCon, activePortals };
        });

        // warn if more than 1 active portal connection exists for any property
        const propertiesWithMoreActiveConnections = tempPropertyConntections.filter(p => p.activePortals?.length > 1);
        if (!_.isEmpty(propertiesWithMoreActiveConnections)) {
          const activeConnections = _.uniq(propertiesWithMoreActiveConnections.flatMap(p => p.activePortals));
          setWarnings(prev => ({ ...prev, activeConnections }));
        }


        // handle EPOST tokens for each management company, iterating all promises of getEpostTokenForCompany
        epostTokenResponses.forEach((settingsResp) => {
          if (settingsResp.status === 'rejected') {
            tempPropertyConntections = tempPropertyConntections.map((prpCon) => {
              if (prpCon.managementCompanyId !== settingsResp.reason?.managementCompanyId) return prpCon;
              // if relevant property connection is found and response status = 401 => EPOST is enabled, but credentials are invalid
              if (settingsResp.reason?.error?.response?.status === 401) {
                return { ...prpCon, epostEnabled: true, epostCredentialsValid: false };
              }
              // if relevant property connection is found, and respose still rejected => EPOST is not enabled
              return { ...prpCon, epostEnabled: false };
            });
          } else {
            // if promise successful
            tempPropertyConntections = tempPropertyConntections.map((prpCon) => {
              if (prpCon.managementCompanyId !== settingsResp.value?.managementCompanyId) return prpCon;
              // if relevant property connection is found => EPOST is enabled and credentials are valid
              return { ...prpCon, epostEnabled: true, epostCredentialsValid: true }; // will only succeed if there are valid credentials
            });
          }
        });

        setPropertyConnections(tempPropertyConntections);
        return Promise.resolve({ properties: prps, connections: domainPortalConnections });
      });
  };

  const loadContracts = (propertyIds: number[], contractIds: number[], prps: PropertyLegacyDto[], connections: ConnectionLegacyDto[]) => {
    if (prps === undefined) return Promise.reject();
    setContracts(prev => prev.startLoading());
    const unitContractPromises = propertyIds.map(pId => unitContractControllerApi.getUnitContractsUsingGET({ propertyId: pId }));
    return Promise.all(unitContractPromises)
      .then((results) => {
        const tempContracts: UnitContractProjectionDto[] = [];
        results.forEach((res) => {
          // from all property contracts filter the ones which are on recipients
          const filteredContracts = res.filter(c => contractIds.includes(c.unitContractId));
          tempContracts.push(...filteredContracts);
        });

        // possibly MV owner contracts, add contracts which are on recipients, but not in the property contracts
        const missingContractIds = contractIds.filter(id => tempContracts.findIndex(uc => uc.unitContractId === id) === -1);
        if (!_.isEmpty(missingContractIds)) {
          return contractControllerApi.getContractsUsingGET({ contractIds: missingContractIds })
            .then((contractsResp) => {
              setContracts((prev) => {
                const temp = tempContracts;
                temp.push(...contractsResp as unknown as UnitContractProjectionDto[]);
                // all contracts of recipients
                return prev.load(temp);
              });

              return Promise.resolve({ contracts: [...tempContracts, ...contractsResp as unknown as UnitContractProjectionDto[]], properties: prps, connections });
            })
            .catch((err) => {
              console.error(err);
              showNotification({
                type: 'error',
                message: tl(messageSendingTranslations.notifications.loadContractsError),
              });
              setContracts(prev => prev.failed());
              return Promise.resolve({ properties: prps, contracts: [], connections });
            });
        }
        setContracts(prev => prev.load(tempContracts));
        return Promise.resolve({ contracts: tempContracts, properties: prps, connections });
      }).catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.loadContractsError),
        });
        setContracts(prev => prev.failed());
        return Promise.resolve({ properties: prps, contracts: [], connections });
      });
  };

  const loadLinkedPropetiesAndContracts = (properties: PropertyLegacyDto[], contracts: UnitContractProjectionDto[], connections: ConnectionLegacyDto[]) => {
    if (properties === undefined) return Promise.reject();

    // contracts from the WEG linked to SEV contracts
    const linkedContractIds = contracts.filter(c => !isNil(c.contractGroupId) && c.contractGroupId !== c.unitContractId).map(c => c.contractGroupId);

    if (isEmpty(linkedContractIds)) {
      return Promise.resolve({ properties, contracts, connections });
    }

    return contractControllerApi.getContractsUsingGET({ contractIds: linkedContractIds })
      .then((contractsResp) => {
        setContracts((prev) => {
          // add all linked contracts
          return prev.load([...prev.data, ...contractsResp as unknown as UnitContractProjectionDto[]]);
        });

        // load linked properties -> the WEGs which have the SEVs connected
        const linkedPropertyIds = contractsResp.filter(ac => linkedContractIds.includes(ac.unitContractId)).map(c => c.propertyId);
        const promises =  _.uniq(linkedPropertyIds).map(id => propertyControllerApi.getPropertyByIdUsingGET({ propertyId: id }));
        return Promise.all(promises)
          .then((propertiesResp) => {
            setProperties((prev) => {
              // add all linked properties
              return prev.load([...prev.data, ...propertiesResp]);
            });

            return Promise.resolve({
              properties: [...properties, ...propertiesResp],
              contracts: [...contracts, ...contractsResp as unknown as UnitContractProjectionDto[]],
              connections
            });
          })
          .catch((err) => {
            console.error(err);
            showNotification({
              type: 'error',
              message: tl(messageSendingTranslations.notifications.loadPropertyError),
            });
            setProperties(prev => prev.failed());
            return Promise.resolve({
              properties: undefined,
              contracts: [...contracts, ...contractsResp as unknown as UnitContractProjectionDto[]],
              connections
            })
          });
      })
      .catch((e) => {
        console.error(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.loadContractsError),
        });
        setContracts(prev => prev.failed());
        return Promise.resolve({ properties, contracts: [], connections })
      });
  }

  const loadDocuments = (propertyParams: MessageSendingProperty[], prps: PropertyLegacyDto[], contractsResp: UnitContractProjectionDto[], connections: ConnectionLegacyDto[]) => {
    if (prps === undefined) return Promise.reject();
    const documentIds = propertyParams?.flatMap(prp => prp.documents.map(dr => dr.documentId));
    const documentIdsSplitRequests = splitRequestParam(documentIds, 'documentIds').map(
      documentIdsSplit => documentControllerApi.findDocumentsFilteredUsingGET({
        documentIds: documentIdsSplit, size: MAX_PAGE_SIZE, sort: 'created', order: FindDocumentsFilteredUsingGETOrderEnum.ASC,
      }),
    );

    setDocumentDatasource(prev => prev.startLoading());
    Promise.all(documentIdsSplitRequests)
      .then((resp) => {
        const documentResponse = resp.map(page => page.content).flat();
        const docs = documentResponse.map((d) => {
          // if document does not belong to a property, set its propertyId based on initialization parameter
          const prpId = d.propertyId || propertyParams.filter(prp => prp.documents.map(pd => pd.documentId).includes(d.id))[0]?.propertyId;

          return ({
            ...d,
            propertyId: prpId,
          });
        });
        setDocumentDatasource(prev => prev.load(groupDocuments(propertyParams, prps, contractsResp, docs)));
        return Promise.resolve({ contracts: contractsResp, connections });
      })
      .catch((e) => {
        setDocumentDatasource(prev => prev.failed());
        console.error(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.errorLoadingDocument),
        });
        return Promise.resolve({ contracts: contractsResp, connections });
      });

      return Promise.resolve({ contracts: contractsResp, connections });
  };

  const loadContractAndContactSyncData = (contracts: UnitContractProjectionDto[], contactIds: number[], connections:  ConnectionLegacyDto[]) => {
    if (_.isEmpty(connections)) {
      setContractsSyncData(prev => prev.load([]));
      setContactsSyncData(prev => prev.load([]));
      return Promise.resolve();
    }
    const connectionId = connections[0].id;
    const unitContractIds = contracts.map(c => c.unitContractId);

    // load contract sync data
    syncDataApi.getSyncDataUsingGET({
      connectionId,
      resourceName: GetSyncDataUsingGETResourceNameEnum.contracts,
      resourceIds: unitContractIds,
      size: MAX_PAGE_SIZE,
    })
      .then((resp) => {
        setContractsSyncData(prev => prev.load(resp.content));
      })
      .catch((e) => {
        setContractsSyncData(prev => prev.failed());
        console.log(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.errorLoadingContractSyncData),
        });
      });

    // load contact sync data
    syncDataApi.getSyncDataUsingGET({
      connectionId,
      resourceName: GetSyncDataUsingGETResourceNameEnum.contacts,
      resourceIds: contactIds,
      size: MAX_PAGE_SIZE,
    })
      .then((resp) => {
        setContactsSyncData(prev => prev.load(resp.content));
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.errorLoadingContactSyncData),
        });
      });
  };

  const getUnitNrForDocument = (d:DocumentProjectionDto, ctrs: UnitContractProjectionDto[]) => ctrs.find(uc => (d?.contractId !== undefined ? uc.unitContractId === d?.contractId : uc.unitId === d?.unitId))?.unitNrSharingDeclaration;

  const groupDocuments = (messageSendingProperties: MessageSendingProperty[], recipientAndLinkedProperties: PropertyLegacyDto[], ctrs: UnitContractProjectionDto[], docs: DocumentProjectionDto[]) => {
    const groupedByProperty = groupBy(docs, 'propertyId');
    const messageSendingPropertyIds = new Set(messageSendingProperties.map(p => p.propertyId));
    const propertiesOfRecipients = recipientAndLinkedProperties.filter(prp => messageSendingPropertyIds.has(prp.id));

    // only group by properties of recipients, not linked properties
    const groupedBySource = propertiesOfRecipients.map((property) => {
      const propertyDocuments = groupedByProperty[property.id];
      const childrenGroup = groupBy(propertyDocuments, ({ sourceType, sourceId }) => `${sourceType}${sourceId}`);
      const docsInParams = messageSendingProperties.find(p => p.propertyId === property.id)?.documents;
      const propertyChildren = Object.values(childrenGroup).map((children) => {
        if (children.length === 1) {
          const unitNr = getUnitNrForDocument(children[0], ctrs);
          return ({
            rowKey: `doc-${children[0]?.id}`,
            ...children[0],
            displayName: [children[0]?.name, unitNr].filter(Boolean).join('_'),
            searchValue: children[0].name,
            movable: true,
          }) as DocumentDatasource;
        }
        return ({
          rowKey: `source-${children[0]?.sourceType}-${children[0]?.sourceId}-prp-${property.id}`,
          displayName: tl(messageSendingTranslations.sourceType[children[0]?.sourceType]),
          sourceType: children[0]?.sourceType,
          sourceId: children[0]?.sourceId,
          propertyId: property.id,
          children: children.map((c) => {
            const unitNr = getUnitNrForDocument(c, ctrs);
            return ({
              ...c,
              rowKey: `doc-${c.id}`,
              movable: false,
              searchValue: c.name,
              displayName: [c.name, unitNr].filter(Boolean).join('_'),
            });
          }).sort((a: DocumentDatasource, b: DocumentDatasource) => {
            const aIndex = docsInParams.findIndex(dp => dp.documentId === a.id);
            const bIndex = docsInParams.findIndex(dp => dp.documentId === b.id);
            return aIndex - bIndex;
          }),
          movable: true,
          searchValue: '',
        }) as DocumentDatasource;
      }).sort((a: DocumentDatasource, b: DocumentDatasource) => {
        const aIndex = isEmpty(a.children)
          ? docsInParams.findIndex(dp => dp.documentId === a.id)
          : docsInParams.findIndex(dp => a.children?.map(ad => ad.id)?.includes(dp.documentId));
        const bIndex = isEmpty(b.children)
          ? docsInParams.findIndex(dp => dp.documentId === b.id)
          : docsInParams.findIndex(dp => b.children?.map(bd => bd.id)?.includes(dp.documentId));

        return aIndex - bIndex;
      });
      return ({
        rowKey: `prp-${property.id}`,
        propertyId: property.id,
        displayName: `${property.propertyIdInternal} · ${property.name}`,
        children: propertyChildren,
        movable: false,
        searchValue: '',
      });
    });

    return groupedBySource;
  };

  const loadContactsByIds = (contactIds: number[]) => {
    setContactsLoading(true);
    return contactControllerApi.getContactsByIdsUsingGET({ contactIds })
      .then(response => Promise.resolve(response))
      .catch((err) => {
        console.error(err);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.loadContactsError),
        });
      })
      .finally(() => {
        setContactsLoading(false);
      });
  };

  const initializeRecipientDistributions = (contacts: ContactLegacyDto[]) => {
    const contractContacts: RecipientContractContact[] = _.uniqWith(documentRecipients?.map(dr => ({ propertyId: dr.propertyId, contractId: dr.contractId, contactId: dr.contactId })), _.isEqual);
    if (!_.isEmpty(contractContacts) && !_.isEmpty(propertyConnections)) {
      setRecipientDistributions((prev) => {
        const newContractContacts = contractContacts.filter(cc => prev.findIndex(r => r.contractId === cc.contractId && r.contactId === cc.contactId) === -1);
        const tempRecipients = prev.filter(r => contractContacts.findIndex(cc => r.contractId === cc.contractId && r.contactId === cc.contactId) !== -1);
        if (!_.isEmpty(newContractContacts)) {
          newContractContacts.forEach((cc) => {
            const selectedContact = contacts?.find(c => c.id === cc.contactId);
            if (selectedContact) {
              const contract = contracts.data?.find(c => c.unitContractId === cc.contractId);
              const activePortalsOfCurrentContractProperty = propertyConnections?.find(pc => pc.propertyId === cc?.propertyId)?.activePortals;

              const epostDisabled = propertyConnections?.filter(pc => pc.propertyId === cc.propertyId
                && pc.epostEnabled && pc.epostCredentialsValid).length === 0;
              const portalDisabled = activePortalsOfCurrentContractProperty?.length === 0 || (contract?.type === UnitContractProjectionDtoTypeEnum.PROPERTY_OWNER
                  && (activePortalsOfCurrentContractProperty?.includes('facilioo') || activePortalsOfCurrentContractProperty?.includes('etg24')));
              const contactPreferences = selectedContact.dispatchPreferences
                ?.map(dp => dp as unknown as MessageCreateDtoLetterDispatchTypeEnum)
                .filter(dp => ![null, undefined, ''].includes(dp)
                  && !(epostDisabled && dp === MessageCreateDtoLetterDispatchTypeEnum.EPOST)
                  && !(portalDisabled && dp === MessageCreateDtoLetterDispatchTypeEnum.PORTAL));
              const distribution = contactPreferences;

              const hasValidAddressForEPOST = validateRecipientAddress(selectedContact);
              const isContractSynced = _.isEmpty(contractsSyncData.data) || validateContractSyncData(contract, contractsSyncData.data);
              const isRecipientContactSynced = _.isEmpty(contactsSyncData.data) || validateContactSyncData(cc, contactsSyncData.data);
              const isPropertySynced = validatePropertySync(contract, contracts.data, properties.data, propertyConnections);

              const contractForSyncDataValidation = getValidatedContract(contract, contracts.data);
              const propertyForLink = getPropertyForLink(contract, properties.data, contracts.data);

              tempRecipients.push({
                key: nanoid(),
                propertyId: cc.propertyId,
                property: propertyForLink,
                contract: contractForSyncDataValidation,
                contractId: cc.contractId,
                contactId: cc.contactId,
                contactType: selectedContact.type,
                distributionType: distribution,
                contactPreferences,
                hasAddress: !_.isEmpty(selectedContact.addresses) && selectedContact.addresses?.[0].street !== undefined,
                hasValidAddressForEPOST,
                isContractSynced,
                isRecipientContactSynced,
                isPropertySynced,
                contractType: contract?.type,
              });
            }
          });
        }
        if (!_.isEmpty(tempRecipients)) {
          setDirty(true);
        }
        return tempRecipients;
      });
    }
  };
};
