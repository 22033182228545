
import { GetUnitContractsUsingGETContractTypesEnum, UnitContractProjectionDtoTypeEnum } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { debounce, groupBy, isEmpty } from 'lodash';
import moment from 'moment';
import { useHeatingCenterLoadedData } from 'pages/HeatingCenter/HeatingCenterEditorPage/stores/CommonData/selectorsHeatingCenterCommonData';
import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { useActiveContractsList } from 'services/UnitContractsList/useActiveContractsList';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { translationsHeatingCenterEditor } from 'pages/HeatingCenter/HeatingCenterEditorPage/translationsHeatingCenterEditor';
import { useUnitsList } from 'services/UnitsList/useUnitsList';
import {
  DataExchangeTableDataSourceItem, getMappingStateLabel, useHeatingCenterDataExchangeColumns,
} from './useHeatingCenterDataExchangeColumns';


/**
 * The non-shareholder units must be grouped, and for that we need a grouping row.
 * The row key is unitId (which is used for row selection) and this row also needs a unique key.
*/
export const GROUPING_ROW_FAKE_UNIT_ID = -1;

const TODAY = moment();


const getMappingState = (isChecked: boolean, mscUnitId: string, wasASetUploaded: boolean) => {
  if (isChecked && wasASetUploaded && !mscUnitId) {
    return 'error' as const;
  }

  if (mscUnitId) {
    return 'success' as const;
  }

  return undefined;
};


export const useHeatingCenterDataExchangeTable = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const scoreStringSearch = useScoreStringSearch();

  const { tl } = useContext(LanguageContext);
  const { property, heatingCenterUnits } = useHeatingCenterLoadedData();

  const { activeContractsList } = useActiveContractsList({
    propertyId: property?.id,
    atDate: TODAY,
    contractTypes: [GetUnitContractsUsingGETContractTypesEnum.OWNER, GetUnitContractsUsingGETContractTypesEnum.TENANT],
  });

  const { unitsList } = useUnitsList({ propertyId: property?.id });

  const { columns, buildingsList, wasASetUploaded } = useHeatingCenterDataExchangeColumns();

  const contractsGroupedByUnit: DataExchangeTableDataSourceItem[] = useMemo(() => {
    const grouped = groupBy(activeContractsList.data, 'unitId');
    const formatted: DataExchangeTableDataSourceItem[] = (unitsList.data ?? []).map((unit) => {
      const group = grouped[unit.id] ?? [];
      const ownerContract = group.find(contract => contract.type === UnitContractProjectionDtoTypeEnum.OWNER);
      const tenantContract = group.find(contract => contract.type === UnitContractProjectionDtoTypeEnum.TENANT);


      const buildingName = buildingsList.data.find(b => b.id === unit.buildingId)?.name;

      const heatingCenterUnit = heatingCenterUnits.data?.find(hcu => hcu.unitId === unit.id);
      const mscUnitId = heatingCenterUnit?.mscUnitId;

      const mappingState = getMappingState(heatingCenterUnit?.checked, mscUnitId, wasASetUploaded);


      const searchValueForRow = `${
        unit.unitNrSharingDeclaration ?? ''
      } ${
        tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.unitTypes[unit.unitType])
      } ${
        ownerContract?.mailingContact?.name ?? ''
      } ${
        tenantContract?.isVacant
          ? tl(translationsHeatingCenterEditor.dataExchangeSection.table.vacancy)
          : tenantContract?.mailingContact?.name
      } ${
        buildingName ?? ''
      } ${
        heatingCenterUnit?.mscUnitId ?? ''
      } ${
        getMappingStateLabel(mscUnitId, tl)
      } ${
        mappingState === 'error'
          ? tl(translationsHeatingCenterEditor.dataExchangeSection.table.columns.mscId.error)
          : (mscUnitId ?? '')
      }`;

      return {
        rowKey: unit.id.toString(),
        unitId: unit.id,
        unitNrSharingDeclaration: unit.unitNrSharingDeclaration,
        unitType: unit.unitType,
        isOwnedByWeg: unit.isOwnedByWeg,
        buildingName,
        mscUnitId,
        isChecked: heatingCenterUnit?.checked,
        ownerContract,
        tenantContract,
        searchValue: searchValueForRow,
        mappingState,
      };
    });


    return formatted;
  }, [activeContractsList.data, buildingsList.data, heatingCenterUnits.data, unitsList.data]);


  const filteredDataSource: DataExchangeTableDataSourceItem[] = useMemo(() => {
    const filteredBySearchValue = scoreStringSearch(contractsGroupedByUnit, searchValue);

    const groupedByUnitType = groupBy(filteredBySearchValue, ({ isOwnedByWeg }) => (isOwnedByWeg ? 'other' : 'shareholder'));

    const groupedDataSource: DataExchangeTableDataSourceItem[] = [
      ...(groupedByUnitType.shareholder ?? []),
      ...(
        isEmpty(groupedByUnitType.other)
          ? []
          : [{
            unitId: GROUPING_ROW_FAKE_UNIT_ID,
            isOwnedByWeg: true,
            children: groupedByUnitType.other,
            searchValue: '',
          }]),
    ];

    return groupedDataSource;
  }, [contractsGroupedByUnit, searchValue]);


  const onChangeSearch = useCallback(debounce(setSearchValue, 200), []);

  const checkedUnitsWithMissingMapping = useMemo(() => (
    contractsGroupedByUnit.filter(contract => contract.mappingState === 'error')
  ), [contractsGroupedByUnit]);

  const wasASetUploadedAndHasMissingMappings = wasASetUploaded && checkedUnitsWithMissingMapping.length > 0;

  return {
    columns,
    rawDataSource: contractsGroupedByUnit,
    dataSourceFilteredBySearch: filteredDataSource,
    loading: activeContractsList.loading,
    onChangeSearch,
    wasASetUploadedAndHasMissingMappings,
    nrCheckedUnitsWithMissingMapping: checkedUnitsWithMissingMapping.length,
  };
};
