import Icon from '@ant-design/icons';
import { GetWkasPagedUsingGETOrderEnum, WkaControllerApi, WkaProjectionDto } from 'api/accounting';
import { ICONS } from 'components/icons';
import { useCurrentOverlayInfo } from 'components/OverlayRoute/services/useCurrentOverlayInfo';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { useSimpleDirtModal } from 'elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import DEFAULT_DATA from 'lib/data';
import { showNotification } from 'lib/Notification';
import { useDomainConfiguration } from 'lib/useDomainConfiguration';
import { MAX_PAGE_SIZE } from 'lib/Utils';
import { economicPlanTranslations } from 'pages/EconomicPlan/economicPlanTranslations';
import {
  getWkaCreateRouteForEconomicPlanCreate, getWkaCreateRouteForEconomicPlanEdit,
  getWkaEditingRouteForEconomicPlanCreate,
  getWkaEditingRouteForEconomicPlanEdit,
} from 'pages/EconomicPlan/routes';
import { EconomicPlanContext } from 'pages/EconomicPlan/services/EconomicPlanContext';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

export const useEconomicPlanWkaSelector = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const [wkas, setWkas] = useState(DEFAULT_DATA<WkaProjectionDto[]>([]));
  const wkaControllerApi = new WkaControllerApi(apiConfiguration('accounting'));
  const { economicPlanAccountBasedWithWkasEnabled } = useDomainConfiguration();
  const { isOverlayOnTop } = useCurrentOverlayInfo();
  const { addDirt } = useSimpleDirtModal();
  const {
    setEconomicPlan,
    economicPlan,
  } = useContext(EconomicPlanContext);
  const {
    selectedProperty,
  } = useContext(PropertyListContext);

  const location = useLocation();

  useEffect(() => {
    if (
      selectedProperty?.data?.id === undefined
      || !economicPlanAccountBasedWithWkasEnabled
      || !isOverlayOnTop
    ) {
      return;
    }

    onLoadWkas();
  }, [economicPlanAccountBasedWithWkasEnabled, selectedProperty?.data?.id, isOverlayOnTop]);


  const onLoadWkas = () => {
    setWkas(prev => prev.startLoading());

    return wkaControllerApi.getWkasPagedUsingGET({
      propertyId: selectedProperty?.data?.id,
      order: GetWkasPagedUsingGETOrderEnum.DESC,
      sort: 'start_date',
      size: MAX_PAGE_SIZE,
    })
      .then((response) => {
        setWkas(prev => prev.load(response.content));
      })
      .catch((error) => {
        setWkas(prev => prev.failed(error));
        showNotification({
          key: 'loadWkaList',
          message: tl(economicPlanTranslations.notifications.economicPlanContext.loadingWkasError),
          type: 'error',
        });
      });
  };

  const options: SelectOption[] = useMemo(() => wkas.data.map(wka => ({
    label: `${wka.title}`,
    value: wka.id,
    link: economicPlan?.data?.id
      ? getWkaEditingRouteForEconomicPlanEdit(selectedProperty?.data?.propertyHrId, economicPlan?.data?.id, wka.id)
      : getWkaEditingRouteForEconomicPlanCreate(selectedProperty?.data?.propertyHrId, wka.id),
  } as SelectOption)), [wkas?.data]);

  const onChange = (wkaIds: number[]) => {
    addDirt();
    setEconomicPlan(prevData => prevData.load({ ...prevData.data, wkaIds }));
  };

  const createWkaRoute = economicPlan?.data?.id ? getWkaCreateRouteForEconomicPlanEdit(selectedProperty?.data?.propertyHrId, economicPlan?.data?.id, location.search) : getWkaCreateRouteForEconomicPlanCreate(selectedProperty?.data?.propertyHrId, location.search);

  const dropdownRenderWithCreateNewWkaOption = content => (
    <>
      <Link
        to={createWkaRoute ?? ''}
        className="add-wka-option"
      >
        <Icon component={ICONS.plus} />
        <span>{tl(economicPlanTranslations.createNewWka)}</span>
      </Link>

      {content}
    </>
  );

  return {
    options,
    onChange,
    values: economicPlan?.data?.wkaIds,
    isFeatureFlagEnabled: economicPlanAccountBasedWithWkasEnabled,
    isDisabled: !selectedProperty.data?.id,
    dropdownRenderWithCreateNewWkaOption,
  };
};
