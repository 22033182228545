import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import Button from '../../../elements/Buttons/Button/Button';
import { economicPlanAccountEditingTranslations } from './economicPlanAccountEditingTranslations';
import PropertyInfo from '../../../elements/PropertyInfo/PropertyInfo';
import { PropertyListContext } from '../../../contexts/PropertyListContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { EconomicPlanContext } from '../services/EconomicPlanContext';
import { useSimpleDirtModal } from '../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

export const EconomicPlanAccountEditingHeader = ({
  save,
}): JSX.Element => {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { selectedDisplayProperty }: any = useContext(PropertyListContext);
  const {
    economicPlan, economicPlanCreationData,
  } = useContext(EconomicPlanContext);

  const { dirty: isDirty } = useSimpleDirtModal();

  const history = useHistory();
  const selectedPropertyId = selectedDisplayProperty.data ? selectedDisplayProperty.data.id : null;

  const headerButtons = (
    <>
      <Button
        type="ghost"
        className="header-button"
        onClick={() => {
          if (economicPlan.data?.id) {
            history.push(`/economic-plan/property/${economicPlan.data.propertyHrId}/view/${economicPlan.data.id}`);
          } else if (selectedDisplayProperty.data?.id) {
            goBack();
          } else {
            history.push('/economic-plan');
          }
        }}
      >
        {tl(economicPlanAccountEditingTranslations.header.cancel)}
      </Button>
      {(economicPlan.data && selectedPropertyId) && (
        <Button
          type="primary"
          className="draft-button header-button"
          onClick={() => save(false)}
          loading={economicPlan.loading || economicPlanCreationData.loading}
          disabled={!economicPlan.data || !selectedPropertyId || !isDirty}
        >
          {tl(economicPlanAccountEditingTranslations.header.save)}
        </Button>
      )}
    </>
  );
  return (
    <>
      <PageHeader
        title={economicPlan.data?.title ?? tl(economicPlanAccountEditingTranslations.header.headerTitle)}
        rightSideComponent={headerButtons}
        subtitle={<PropertyInfo property={selectedDisplayProperty.data} />}
        showLogo
      />
    </>
  );
};
